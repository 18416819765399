import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { Chart, ArcElement, Tooltip } from 'chart.js';
import { Pie } from 'react-chartjs-2';

import classes from '../Portfolio.module.scss';

Chart.register(ArcElement, Tooltip);

const PortfolioByType = ({ data, value, typesNames, typesOrder }) => {
  const [showRebalance, setShowRebalance] = useState(false);
  const [rebalance, setRebalance] = useState({});

  const rebalanceTotal = useMemo(
    () =>
      Object.values(rebalance).reduce(
        (acc, value) => acc + parseInt(value || 0),
        0
      ),
    [rebalance]
  );

  const resetRebalance = useCallback(() => {
    setRebalance(
      Object.keys(typesOrder).reduce((acc, key) => {
        acc[key] = 0;
        return acc;
      }, {})
    );
  }, [typesOrder, setRebalance]);

  useEffect(() => {
    resetRebalance();
  }, [resetRebalance]);

  const valueTotal = value + rebalanceTotal;

  const types = useMemo(
    () =>
      Object.values(
        data.reduce((acc, asset) => {
          if (acc[asset.Type]) {
            const total = asset.Quote
              ? asset.Quantity * asset.Quote
              : asset.Quantity;
            acc[asset.Type] = {
              ...acc[asset.Type],
              value: acc[asset.Type].value + (100 * total) / valueTotal,
              target: acc[asset.Type].target + asset.Target,
            };
          } else {
            const total =
              (asset.Quote ? asset.Quantity * asset.Quote : asset.Quantity) +
              parseInt(rebalance[asset.Type] || 0);
            acc[asset.Type] = {
              name: asset.Type,
              value: (100 * total) / valueTotal,
              target: asset.Target,
            };
          }
          return acc;
        }, {})
      ).sort((a, b) => typesOrder[a.name] - typesOrder[b.name]),
    [data, typesOrder, rebalance, valueTotal]
  );

  return (
    <>
      <div className={classes.rebalance}>
        <button
          onClick={() =>
            setShowRebalance((prev) => {
              if (prev) {
                resetRebalance();
              }
              return !prev;
            })
          }
        >
          {showRebalance ? 'Ocultar simulação de reforço' : 'Simular reforço'}
        </button>
        {showRebalance && (
          <fieldset>
            <legend>Tipos de activos</legend>
            {types.map((type) => (
              <label key={type.name}>
                <span>{typesNames[type.name]}</span>
                <input
                  type="number"
                  min={0}
                  size={8}
                  value={rebalance[type.name]}
                  onChange={(e) => {
                    setRebalance((prev) => ({
                      ...prev,
                      [type.name]: parseInt(e.target.value),
                    }));
                  }}
                />{' '}
                €
              </label>
            ))}
          </fieldset>
        )}
      </div>
      <table className={classes.assetsTable}>
        <thead>
          <tr>
            <th className={classes.name}>Categoria</th>
            <th className={classes.target}>Objectivo</th>
            <th className={classes.percentage}>Peso</th>
            <th className={classes.total}>Valor total</th>
          </tr>
        </thead>
        <tbody>
          {types.map((type) => (
            <tr className={classes[type.name]} key={type.name}>
              <th className={classes.name}>
                <span className={classes[type.name]}>
                  {typesNames[type.name]}
                </span>
              </th>
              <td className={classes.target}>
                <span className={classes[type.name]}>
                  {type.target
                    ? `${Number(type.target.toFixed(2)).toLocaleString(
                        'pt-PT'
                      )}%`
                    : '-'}
                </span>
              </td>
              <td className={classes.percentage}>
                <span className={classes[type.name]}>
                  {Number(type.value.toFixed(2)).toLocaleString('pt-PT')}%
                </span>
              </td>
              <td className={classes.total}>
                <strong className={classes[type.name]}>
                  {Number(
                    ((valueTotal * type.value) / 100).toFixed(2)
                  ).toLocaleString('pt-PT')}{' '}
                  €
                </strong>
              </td>
            </tr>
          ))}
          <tr className={classes.total}>
            <th className={classes.name}>
              <span className={classes.total}>Total</span>
            </th>
            <td className={classes.target}>
              <span className={classes.total}>&nbsp;</span>
            </td>
            <td className={classes.percentage}>
              <span className={classes.total}>&nbsp;</span>
            </td>
            <td className={classes.total}>
              <strong className={classes.total}>
                {Number((value + rebalanceTotal).toFixed(2)).toLocaleString(
                  'pt-PT'
                )}{' '}
                €
              </strong>
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <div className={classes.pieChartContainer}>
        <Pie
          data={{
            labels: types.map(
              (type) =>
                `${typesNames[type.name]} (${Number(
                  type.value.toFixed(2)
                ).toLocaleString('pt-PT')}%)`
            ),
            datasets: [
              {
                data: types.map((type) => (type.value < 1 ? 1 : type.value)),
                backgroundColor: types.map((type) => {
                  if (type.name === 'stock') {
                    return '#e1523e';
                  }
                  if (type.name === 'bond') {
                    return '#2271b1';
                  }
                  if (type.name === 'gold') {
                    return '#ffa400';
                  }
                  if (type.name === 'ppr') {
                    return '#80F3D9';
                  }
                  if (type.name === 'crypto') {
                    return '#712F79';
                  }
                  if (type.name === 'cash') {
                    return '#50a684';
                  }
                  return '#FFF';
                }),
                hoverOffset: 20,
              },
            ],
          }}
          options={{
            layout: {
              padding: 20,
            },
            plugins: {
              tooltip: {
                callbacks: {
                  label: function (context) {
                    return context.label;
                  },
                },
              },
              legend: {
                display: false,
              },
            },
          }}
        />
      </div>
    </>
  );
};

export default PortfolioByType;
