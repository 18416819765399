import React from 'react';

import { availablePortfolios, riskLabels } from './constants';

import classes from '../Simulator.module.scss';

const EstimationsData = ({
  portfolio,
  setPortfolio,
  selectedPortfolio,
  customAllocations,
  setCustomAllocations,
  expectedReturn,
  setExpectedReturn,
  monthsToSimulate,
  portfolioSimulationStart,
  setPortfolioSimulationStart,
  inflation,
  setInflation,
}) => (
  <fieldset className={classes.fieldset}>
    <legend>Estimativas</legend>
    <label className={classes.label}>
      Tipo de carteira de investimento{' '}
      <select
        name="portfolio"
        value={portfolio}
        onChange={(e) => setPortfolio(e.target.value)}
      >
        <option value="fix">Taxa fixa</option>
        <option value="custom">Alocação personalizada</option>
        {availablePortfolios.map((item) => (
          <option value={item.slug} key={item.slug}>
            {item.name}
          </option>
        ))}
      </select>
    </label>
    {selectedPortfolio && portfolio !== 'custom' && (
      <div>
        Risco da carteira:{' '}
        <span className={classes[selectedPortfolio.risk]}>
          {riskLabels[selectedPortfolio.risk]}
        </span>
        <br />
        ETFs necessários: {selectedPortfolio.etfs}
        <br />
        Fonte dos dados:{' '}
        <a href={selectedPortfolio.source} target="_blank" rel="noreferrer">
          {selectedPortfolio.name}
        </a>
      </div>
    )}
    {portfolio === 'fix' && (
      <label className={classes.label}>
        Rentabilidade anual da carteira (%){' '}
        <input
          type="number"
          min="0"
          size="4"
          value={expectedReturn}
          onChange={(e) => setExpectedReturn(e.target.value)}
        />
      </label>
    )}
    {portfolio === 'custom' && (
      <>
        {availablePortfolios
          .filter((item) => item.pureAsset)
          .map((item) => (
            <label className={classes.label} key={item.slug}>
              {item.name} (%){' '}
              <input
                type="number"
                min="0"
                size="4"
                value={customAllocations[item.slug]}
                onChange={(e) =>
                  setCustomAllocations((prev) => {
                    const newAllocations = {
                      ...prev,
                      [item.slug]: Number(e.target.value),
                    };
                    if (
                      Object.values(newAllocations).reduce(
                        (acc, item) => acc - item,
                        100
                      ) >= 0
                    ) {
                      return newAllocations;
                    }
                    return prev;
                  })
                }
              />
            </label>
          ))}
        Dinheiro:{' '}
        {Object.values(customAllocations).reduce(
          (acc, item) => acc - item,
          100
        )}
        %
      </>
    )}
    {(selectedPortfolio || portfolio === 'custom') && (
      <>
        {selectedPortfolio.data.length - monthsToSimulate - 1 <= 0 ? (
          <p className={classes.warning}>
            A simulação é demasiado longa para ser simulada com esta carteira.
          </p>
        ) : (
          <label className={classes.label}>
            Mês de início da simulação{' '}
            <input
              className={classes.range}
              type="range"
              min="0"
              max={selectedPortfolio.data.length - monthsToSimulate - 1}
              value={portfolioSimulationStart}
              onChange={(e) =>
                setPortfolioSimulationStart(Number(e.target.value))
              }
            />{' '}
            {new Date(
              selectedPortfolio.data[portfolioSimulationStart]?.year,
              selectedPortfolio.data[portfolioSimulationStart]?.month - 1,
              1
            ).toLocaleString('pt-PT', { month: 'long', year: 'numeric' })}
          </label>
        )}
      </>
    )}
    <label className={classes.label}>
      Inflacção anual (%){' '}
      <input
        type="number"
        min="0"
        size="4"
        value={inflation}
        onChange={(e) => setInflation(e.target.value)}
      />
    </label>
  </fieldset>
);

export default EstimationsData;
