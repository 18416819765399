import React from 'react';
import { Link } from 'react-router-dom';

import useRequest from 'utils/useRequest';

import classes from './RecentPosts.module.scss';

const RecentPosts = ({ ignore }) => {
  const { data, loading, error } = useRequest({
    url: 'articles?_sort=Date:DESC&_limit=6',
    method: 'GET',
  });

  if (loading || error || !data) {
    return null;
  }

  const recentPosts = data
    .reduce((acc, article) => {
      if (article.id !== ignore) {
        acc.push(article);
      }
      return acc;
    }, [])
    .slice(0, 5);

  if (!recentPosts.length) {
    return null;
  }

  return (
    <section className={classes.recentPosts}>
      <h1 className={classes.title}>Artigos recentes</h1>
      <ul className={classes.list}>
        {recentPosts.map((article) => (
          <li key={article.slug} className={classes.article}>
            <Link className={classes.link} to={`/artigo/${article.slug}`}>
              {article.title}
            </Link>
          </li>
        ))}
      </ul>
    </section>
  );
};

export default RecentPosts;
