import { useState } from 'react';
import { parse } from 'papaparse';

import useRequest from 'utils/useRequest';

import classes from './ImportTransactionsForm.module.scss';

const ImportTransactionsForm = ({ accounts, onSuccess }) => {
  const [importing, setImporting] = useState(false);

  const { request } = useRequest({
    url: `/bank-transactions/`,
    method: 'POST',
    callback: true,
  });

  const [transactionsToImport, setTransactionsToImport] = useState();

  const importTransactions = (event) => {
    event.preventDefault();
    const account = new FormData(event.target).get('account');
    let imported = 0;
    setImporting(true);
    transactionsToImport.forEach((transaction) => {
      request({
        data: {
          date: new Date(transaction[0]),
          description: transaction[1],
          value: transaction[2],
          bank_account: account,
        },
      }).then(() => {
        imported++;
        if (imported === transactionsToImport.length) {
          setTransactionsToImport();
          setImporting(false);
          onSuccess();
        }
      });
      // TODO: handle error
    });
  };

  return (
    <form onSubmit={importTransactions} className={classes.form}>
      <div>
        <strong>Importar movimentos</strong>
      </div>
      <input
        disabled={importing}
        className={classes.file}
        type="file"
        name="transactions"
        onChange={(event) => {
          const r = new FileReader();
          r.onload = (e) => {
            setTransactionsToImport(
              parse(e.target.result).data.map((item) => [
                item[0],
                item[1],
                Number(item[2].replace(/\s+/g, '').replace(',', '.')),
              ])
            );
          };
          r.readAsText(event.target.files[0]);
        }}
      />
      {transactionsToImport && (
        <>
          <output>
            <table className={classes.table}>
              <thead>
                <tr>
                  <th>Data</th>
                  <th>Descrição</th>
                  <th>Valor</th>
                </tr>
              </thead>
              <tbody>
                {transactionsToImport.map((transaction, index) => (
                  <tr key={index}>
                    <td>{transaction[0]}</td>
                    <td>{transaction[1]}</td>
                    <td>{transaction[2]}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </output>
          <label className={classes.label}>
            <span>Conta dos movimentos</span>
            <select type="text" name="account" required disabled={importing}>
              {accounts
                .sort((a, b) => a.title.localeCompare(b.title))
                .map((account) => {
                  if (!account.key) {
                    return (
                      <option value={account.id} key={account.id}>
                        {account.title}
                      </option>
                    );
                  }
                  return null;
                })}
            </select>
          </label>
          <button className={classes.submit} disabled={importing}>
            Importar movimentos
          </button>
        </>
      )}
    </form>
  );
};

export default ImportTransactionsForm;
