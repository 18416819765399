import React, { useMemo } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend
);

const Chart = React.memo(({ data }) => {
  const chartData = useMemo(
    () =>
      data.reduce(
        (acc, month) => {
          if (month.month % 60 === 0) {
            acc.labels.push(month.month / 12);
          } else {
            acc.labels.push('');
          }
          acc.investment.push(month.total);
          acc.capital.push(month.capital);
          return acc;
        },
        {
          labels: [],
          investment: [],
          capital: [],
        }
      ),
    [data]
  );

  return (
    <Line
      data={{
        labels: chartData.labels,
        datasets: [
          {
            label: 'Investimento',
            data: chartData.investment,
            borderColor: '#FFA400',
          },
          {
            label: 'Capital acumulado',
            data: chartData.capital,
            borderColor: '#8031A7',
          },
        ],
      }}
      options={{
        plugins: {
          tooltip: {
            enabled: false,
          },
        },
        pointRadius: 0,
        scales: {
          xAxis: {
            title: {
              display: true,
              text: 'Anos desde o início do investimento',
            },
            ticks: {
              autoSkip: false,
            },
            grid: {
              color: (context) =>
                context.tick.value % 12 === 0
                  ? 'rgba(0,0,0,0.2)'
                  : 'transparent',
            },
          },
          yAxis: {
            min: 0,
            grid: {
              color: 'rgba(0,0,0,0.2)',
            },
          },
        },
      }}
    />
  );
});

export default Chart;
