import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import useRequest from 'utils/useRequest';
import Loading from 'utils/Loading';
import Error from 'utils/Error';

import classes from './Archive.module.scss';

const Archive = () => {
  const { data, loading, error } = useRequest({
    url: 'articles?_sort=Date:DESC',
    method: 'GET',
  });

  const dataByDate = useMemo(
    () =>
      data?.reduce((acc, article) => {
        const year = article.date.substring(0, 4);
        const month = article.date.substring(5, 7);
        const day = article.date.substring(8, 10);
        if (!acc[year]) {
          acc[year] = {};
        }
        if (!acc[year][month]) {
          acc[year][month] = [];
        }
        acc[year][month].push({ ...article, day });
        return acc;
      }, {}),
    [data]
  );

  if (loading) {
    return <Loading />;
  }

  if (error || !data) {
    return <Error />;
  }

  return (
    <article>
      <h1 className={classes.title}>Arquivo</h1>
      {data.length > 0 ? (
        <ul className={classes.list}>
          {Object.entries(dataByDate)
            .sort((a, b) => b[0] - a[0])
            .map(([year, months]) => (
              <li key={year} className={classes.year}>
                <div className={classes.yearLabel}>{year}</div>
                <ul>
                  {Object.entries(months)
                    .sort((a, b) => b[0] - a[0])
                    .map(([month, articles]) => (
                      <li key={month} className={classes.month}>
                        <div className={classes.monthLabel}>
                          {new Date(`${year}-${month}-01`).toLocaleDateString(
                            'pt-PT',
                            {
                              month: 'long',
                            }
                          )}
                        </div>
                        <ul>
                          {articles.map((article) => (
                            <li key={article.id} className={classes.article}>
                              <span className={classes.day}>{article.day}</span>
                              <Link
                                className={classes.link}
                                to={`/artigo/${article.slug}`}
                              >
                                {article.title}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </li>
                    ))}
                </ul>
              </li>
            ))}
        </ul>
      ) : (
        <p>Ainda não há artigos disponíveis</p>
      )}
    </article>
  );
};

export default Archive;
