import React, { useState } from 'react';
import classnames from 'classnames';

import Article from 'components/Article';

import Euribor from './Data/Euribor';
import Stocks from './Data/Stocks';

import classes from './Data/Data.module.scss';

const stocks = {
  sp500: {
    title: 'S&P 500',
    component: <Stocks label="S&amp;P 500" ticker="^GSPC-USD" currency="USD" />,
  },
  dow30: {
    title: 'Dow 30',
    component: (
      <Stocks
        label="Dow Jones Industrial Average"
        ticker="^DJI-USD"
        currency="USD"
      />
    ),
  },
  nasdaq: {
    title: 'NASDAQ',
    component: (
      <Stocks label="NASDAQ Composite" ticker="^IXIC-USD" currency="USD" />
    ),
  },
  russell: {
    title: 'Russell',
    component: <Stocks label="Russell 2000" ticker="^RUT-USD" currency="USD" />,
  },
};

const currencies = {
  eurusd: {
    title: 'EUR/USD',
    component: <Stocks label="EUR/USD" ticker="EURUSD=X-USD" currency="USD" />,
  },
  chfeur: {
    title: 'CHF/EUR',
    component: <Stocks label="CHF/EUR" ticker="CHFEUR=X-EUR" currency="EUR" />,
  },
  btceur: {
    title: 'BTC/EUR',
    component: <Stocks label="BTC/EUR" ticker="BTC-EUR-EUR" currency="EUR" />,
  },
};

const commodities = {
  gold: {
    title: 'Ouro',
    component: <Stocks label="Gold" ticker="GC=F-USD" currency="USD" />,
  },
  oil: {
    title: 'Petróleo',
    component: <Stocks label="Crude Oil" ticker="CL=F-USD" currency="USD" />,
  },
};

const others = {
  euribor: {
    title: 'Euribor',
    component: <Euribor />,
  },
};

const Data = () => {
  const [stockTab, setStockTab] = useState('sp500');
  const [currencyTab, setCurrencyTab] = useState('eurusd');
  const [commodityTab, setCommodityTab] = useState('gold');
  const [otherTab, setOtherTab] = useState('euribor');
  return (
    <Article article={{ title: 'Indicadores' }}>
      <section>
        <h1>Índices bolsistas</h1>
        <nav className={classes.tabs}>
          <ul>
            {Object.entries(stocks).map(([key, tab]) => (
              <li key={key}>
                <button
                  onClick={() => {
                    setStockTab(key);
                  }}
                  className={classnames({
                    [classes.selected]: stockTab === key,
                  })}
                >
                  {tab.title}
                </button>
              </li>
            ))}
          </ul>
        </nav>
        {stocks[stockTab].component}
      </section>
      <section>
        <h1>Moedas</h1>
        <nav className={classes.tabs}>
          <ul>
            {Object.entries(currencies).map(([key, tab]) => (
              <li key={key}>
                <button
                  onClick={() => {
                    setCurrencyTab(key);
                  }}
                  className={classnames({
                    [classes.selected]: currencyTab === key,
                  })}
                >
                  {tab.title}
                </button>
              </li>
            ))}
          </ul>
        </nav>
        {currencies[currencyTab].component}
      </section>
      <section>
        <h1>Comódites</h1>
        <nav className={classes.tabs}>
          <ul>
            {Object.entries(commodities).map(([key, tab]) => (
              <li key={key}>
                <button
                  onClick={() => {
                    setCommodityTab(key);
                  }}
                  className={classnames({
                    [classes.selected]: commodityTab === key,
                  })}
                >
                  {tab.title}
                </button>
              </li>
            ))}
          </ul>
        </nav>
        {commodities[commodityTab].component}
      </section>
      <section>
        <h1>Outros</h1>
        <nav className={classes.tabs}>
          <ul>
            {Object.entries(others).map(([key, tab]) => (
              <li key={key}>
                <button
                  onClick={() => {
                    setOtherTab(key);
                  }}
                  className={classnames({
                    [classes.selected]: otherTab === key,
                  })}
                >
                  {tab.title}
                </button>
              </li>
            ))}
          </ul>
        </nav>
        {others[otherTab].component}
      </section>
    </Article>
  );
};

export default Data;
