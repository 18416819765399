import React, { useMemo } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

import classes from '../Simulator.module.scss';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend
);

const FullDataChart = React.memo(
  ({ data, selectedPortfolio }) => {
    const chartData = useMemo(
      () =>
        data.reduce(
          (acc, item, index) => {
            acc.data.push(item[item.length - 1].capital);
            if (index % 12 === 0) {
              acc.labels.push(selectedPortfolio.data[index]?.year);
            } else {
              acc.labels.push('');
            }
            if (Math.abs(item[item.length - 1].capital) === 0) {
              acc.fail++;
            }
            return acc;
          },
          { data: [], labels: [], fail: 0 }
        ),
      [data, selectedPortfolio]
    );

    const successRate = useMemo(
      () => 1 - chartData.fail / chartData.data.length,
      [chartData.data, chartData.fail]
    );

    return (
      <>
        <p className={successRate > 0.9 ? classes.success : classes.fail}>
          Este plano tem uma taxa de sucesso histórica de{' '}
          {Math.floor(successRate * 100)}%.
          <br />
        </p>
        <Line
          data={{
            labels: chartData.labels,
            datasets: [
              {
                label: 'Valor final',
                data: chartData.data,
                borderColor: '#FFA400',
              },
            ],
          }}
          options={{
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                enabled: false,
              },
            },
            pointRadius: 1,
            scales: {
              xAxis: {
                title: {
                  display: true,
                  text: 'Início do investimento',
                },
                max: chartData.data.length,
              },
              yAxis: {
                min: 0,
                grid: {
                  color: 'rgba(0,0,0,0.2)',
                },
                title: {
                  display: true,
                  text: 'Valor final do investimento',
                },
              },
            },
          }}
        />
      </>
    );
  }
);

export default FullDataChart;
