import React from 'react';

import classes from '../Simulator.module.scss';

const InvestmentData = ({
  initialCapital,
  setInitialCapital,
  monthBoost,
  setMonthBoost,
  monthBoostIncrease,
  setMonthBoostIncrease,
}) => (
  <fieldset className={classes.fieldset}>
    <legend>Fase de acumulação</legend>
    <label className={classes.label}>
      Investimento inicial (€){' '}
      <input
        type="number"
        min="0"
        size="8"
        value={initialCapital}
        onChange={(e) => setInitialCapital(parseInt(e.target.value))}
      />
    </label>
    <label className={classes.label}>
      Reforço mensal (€){' '}
      <input
        type="number"
        min="0"
        size="7"
        value={monthBoost}
        onChange={(e) => setMonthBoost(parseInt(e.target.value))}
      />
    </label>
    <label className={classes.label}>
      Incremento anual do reforço (%){' '}
      <input
        type="number"
        min="0"
        size="4"
        value={monthBoostIncrease}
        onChange={(e) => setMonthBoostIncrease(parseInt(e.target.value))}
      />
    </label>
  </fieldset>
);

export default InvestmentData;
