import React, { useState, useEffect, useCallback } from 'react';
import classnames from 'classnames';
import { stringify } from 'query-string';
import { debounce } from 'lodash';

import FullDataChart from './FullDataChart';
import Chart from './Chart';

import classes from '../Simulator.module.scss';

const debouncer1 = debounce((call) => {
  call();
}, 100);

const debouncer2 = debounce((call) => {
  call();
}, 100);

const Output = ({
  currentAge,
  barista,
  baristaAge,
  retirementAge,
  legalRetirementAge,
  initialCapital,
  monthBoost,
  monthBoostIncrease,
  withdrawalValue,
  expectedReturn,
  portfolio,
  customAllocations,
  portfolioSimulationStart,
  inflation,
  selectedPortfolio,
  monthsToSimulate,
}) => {
  const [fullData, setFullData] = useState();
  const [simulationData, setSimulationData] = useState();

  const computeData = useCallback(
    (start) => {
      const withdrawalBegin = (retirementAge - currentAge) * 12 + 1;
      const baristaBegin = barista && (baristaAge - currentAge) * 12 + 1;
      const withdrawalEnd = (legalRetirementAge - currentAge) * 12;
      const monthlyInflation = Math.pow(1 + inflation / 100, 1 / 12) - 1;
      let total = Number(initialCapital);
      let capital = total;
      return [...Array(Number(withdrawalEnd) + 1)].map((_, i) => {
        const year = Math.floor((i - 1) / 12);
        const monthReturn = selectedPortfolio
          ? selectedPortfolio.data[i + start].return / 100
          : Math.pow(1 + expectedReturn / 100, 1 / 12) - 1;
        let monthData;
        if (i === 0) {
          monthData = {
            month: i,
            type: 'start',
            boost: 0,
            total: initialCapital,
            capital: initialCapital,
          };
        } else if (i < withdrawalBegin) {
          const boost =
            barista && i >= baristaBegin
              ? 0
              : Math.round(
                  monthBoost * Math.pow(1 + monthBoostIncrease / 100, year)
                );
          capital = capital * (1 + monthReturn) + boost;
          total += boost;
          monthData = {
            month: i,
            type: 'acc',
            boost,
            total,
            capital,
          };
        } else {
          const inflationAdjustedWithdrawalValue =
            withdrawalValue * Math.pow(1 + monthlyInflation, i);
          const maxWithdrawalValue =
            capital > inflationAdjustedWithdrawalValue
              ? inflationAdjustedWithdrawalValue
              : capital;
          capital =
            capital * (1 + monthReturn) - inflationAdjustedWithdrawalValue;
          if (capital < 0) {
            capital = 0;
          }
          monthData = {
            month: i,
            type: 'dist',
            boost: -maxWithdrawalValue,
            total,
            capital,
          };
        }
        return monthData;
      });
    },
    [
      currentAge,
      barista,
      baristaAge,
      retirementAge,
      legalRetirementAge,
      expectedReturn,
      initialCapital,
      monthBoost,
      monthBoostIncrease,
      withdrawalValue,
      selectedPortfolio,
      inflation,
    ]
  );

  useEffect(() => {
    debouncer1(() => {
      if (selectedPortfolio) {
        let fullDataTemp = [];
        for (
          let index = 0;
          index < selectedPortfolio.data.length - monthsToSimulate;
          index++
        ) {
          const iterationData = computeData(index);
          fullDataTemp.push(iterationData);
        }
        setFullData(fullDataTemp);
      } else {
        setFullData();
      }
    });
  }, [selectedPortfolio, monthsToSimulate, computeData]);

  useEffect(() => {
    debouncer2(() => {
      if (fullData) {
        setSimulationData(fullData?.[portfolioSimulationStart]);
      } else {
        setSimulationData(computeData(0));
      }
    });
  }, [fullData, portfolioSimulationStart, computeData]);

  if (!simulationData) {
    return null;
  }

  const fail =
    Math.abs(simulationData[simulationData.length - 1].boost) < withdrawalValue;
  const remainingValue = simulationData[simulationData.length - 1].capital;

  const query = stringify({
    currentAge,
    barista,
    baristaAge,
    retirementAge,
    legalRetirementAge,
    initialCapital,
    monthBoost,
    monthBoostIncrease,
    withdrawalValue,
    expectedReturn: portfolio === 'fix' ? expectedReturn : undefined,
    portfolio,
    customAllocations:
      portfolio === 'custom' ? JSON.stringify(customAllocations) : undefined,
    portfolioSimulationStart:
      portfolio !== 'fix' ? portfolioSimulationStart : undefined,
    inflation,
  });
  const linkToShare = `${process.env.REACT_APP_URL}simulador/?${query}`;

  return (
    <output>
      {selectedPortfolio && fullData && (
        <div className={classes.chart}>
          <FullDataChart
            data={fullData}
            selectedPortfolio={selectedPortfolio}
          />
        </div>
      )}
      {fail ? (
        <p className={classes.fail}>
          {selectedPortfolio ? (
            <>
              Começando em{' '}
              {new Date(
                selectedPortfolio.data[portfolioSimulationStart]?.year,
                selectedPortfolio.data[portfolioSimulationStart]?.month - 1,
                1
              ).toLocaleString('pt-PT', { month: 'long', year: 'numeric' })}
              , neste plano o capital não chegaria ao fim da fase de retirada.
            </>
          ) : (
            <>
              Assim não vai dar. Com este plano o capital não chega ao fim da
              fase de retirada.
            </>
          )}
        </p>
      ) : (
        <>
          <p className={classes.success}>
            {selectedPortfolio ? (
              <>
                Começando em{' '}
                {new Date(
                  selectedPortfolio.data[portfolioSimulationStart]?.year,
                  selectedPortfolio.data[portfolioSimulationStart]?.month - 1,
                  1
                ).toLocaleString('pt-PT', { month: 'long', year: 'numeric' })}
                , este plano resultaria e sobrariam ainda{' '}
                {Math.round(remainingValue).toLocaleString('pt-PT')} € brutos no
                fim da retirada.
              </>
            ) : (
              <>
                Este plano resultaria e sobrariam ainda{' '}
                {Math.round(remainingValue).toLocaleString('pt-PT')} € brutos no
                fim da retirada.
              </>
            )}
          </p>
          <label className={classes.label}>
            Hiperligação para partilhar
            <br />
            <div className={classes.shareLink}>
              <input readOnly value={linkToShare} />
              <button
                type="button"
                onClick={() => {
                  navigator.clipboard.writeText(linkToShare);
                }}
              >
                Copiar hiperligação
              </button>
            </div>
          </label>
        </>
      )}
      <div className={classes.chart}>
        <Chart data={simulationData} />
      </div>
      <div className={classes.tableContainer}>
        <table className={classes.table}>
          <thead>
            <tr>
              <th>Mês</th>
              <th>Reforço/retirada</th>
              <th>Investimento</th>
              <th>Capital bruto</th>
            </tr>
          </thead>
          <tbody>
            {simulationData.map((month) => (
              <tr key={month.month}>
                <td>{month.month}</td>
                <td className={classnames(classes.boost, classes[month.type])}>
                  {month.month && Math.abs(Math.round(month.boost)) > 0 ? (
                    <>{Math.round(month.boost).toLocaleString('pt-PT')} €</>
                  ) : (
                    '-'
                  )}
                </td>
                <td>{Math.round(month.total).toLocaleString('pt-PT')} €</td>
                <td
                  className={classnames({
                    [classes.dead]: month.capital <= 0 && month.month > 0,
                  })}
                >
                  {Math.round(month.capital).toLocaleString('pt-PT')} €
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </output>
  );
};

export default Output;
