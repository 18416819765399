import { useState, useMemo } from 'react';
import classnames from 'classnames';

import useRequest from 'utils/useRequest';
import Loading from 'utils/Loading';

import classes from './Modal.module.scss';
import upperClasses from '../Transactions.module.scss';

const UpdateCoverflexModal = ({ accounts, onClose }) => {
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState();

  const { request: refresh } = useRequest({
    url: 'runner',
    method: 'GET',
    callback: true,
  });

  const { request: saveTransaction } = useRequest({
    url: `/bank-transactions/`,
    method: 'POST',
    callback: true,
  });

  const fetchNewTransactions = () =>
    new Promise(() => {
      setLoading(true);
      refresh({
        params: {
          action: 'reload-transactions',
          type: 'nordigen',
        },
      })
        .then((data) => {
          setList(
            data.transactions.map((item) => ({ ...item, checked: true }))
          );
        })
        .finally(() => {
          setLoading(false);
        });
    });

  const transactionsToImport = useMemo(
    () => list?.filter((item) => item.checked),
    [list]
  );

  const importTransactions = () => {
    if (transactionsToImport?.length === 0) {
      onClose({});
      return;
    }
    let imported = 0;
    setLoading(true);
    transactionsToImport.forEach((transaction) => {
      saveTransaction({ data: transaction }).then(() => {
        imported++;
        if (imported === transactionsToImport.length) {
          onClose({ saved: true });
        }
      });
      // TODO: handle error
    });
  };

  return (
    <div className={classes.overlay}>
      {loading ? (
        <Loading />
      ) : (
        <form
          style={{ maxWidth: '95%' }}
          onSubmit={(event) => {
            event.preventDefault();
            if (!list) {
              fetchNewTransactions();
            } else {
              importTransactions();
            }
          }}
        >
          {list?.length > 0 && (
            <ul className={upperClasses.transactions}>
              {list.map((transaction) => (
                <li
                  key={transaction.transaction_id}
                  className={upperClasses.transaction}
                >
                  <div className={upperClasses.checkbox}>
                    <input
                      type="checkbox"
                      checked={transaction.checked}
                      onChange={() => {
                        setList((prev) => {
                          const index = prev.findIndex(
                            (item) =>
                              item.transaction_id === transaction.transaction_id
                          );
                          let newList = [...prev];
                          newList[index] = {
                            ...prev[index],
                            checked: !prev[index].checked,
                          };
                          return newList;
                        });
                      }}
                    />
                  </div>
                  <div className={upperClasses.account}>
                    {accounts[transaction.bank_account].title || '-'}
                  </div>
                  <div
                    className={upperClasses.description}
                    title={transaction.description}
                  >
                    {transaction.description}
                  </div>
                  <div
                    className={classnames(upperClasses.value, {
                      [upperClasses.positive]: transaction.value > 0,
                      [upperClasses.negative]: transaction.value < 0,
                    })}
                  >
                    {transaction.value.toLocaleString('pt-PT')} €
                  </div>
                </li>
              ))}
            </ul>
          )}
          {list?.length === 0 && <p>Nenhum movimento para importar</p>}
          <button
            className={classes.submit}
            disabled={list?.length > 0 && transactionsToImport?.length === 0}
          >
            {!list
              ? 'Obter novos movimentos'
              : list?.length === 0
              ? 'OK'
              : 'Gravar'}
          </button>
          <button className={classes.submit} type="button" onClick={onClose}>
            Cancelar
          </button>
        </form>
      )}
    </div>
  );
};

export default UpdateCoverflexModal;
