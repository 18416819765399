import { useState } from 'react';

import useRequest from 'utils/useRequest';
import Loading from 'utils/Loading';

import classes from './Modal.module.scss';

const UpdateCoverflexModal = ({ onClose }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [otpField, setOtpField] = useState(false);
  const [otp, setOtp] = useState();
  const [loading, setLoading] = useState(false);

  const { request: refresh } = useRequest({
    url: 'runner',
    method: 'GET',
    callback: true,
  });

  const fetchNewTransactions = () =>
    new Promise(() => {
      setLoading(true);
      refresh({
        params: {
          action: 'reload-transactions',
          type: 'coverflex',
          email,
          password,
          otp,
        },
      })
        .then((data) => {
          if (data.ok && !data.transactions) {
            setOtpField(true);
            setLoading(false);
            return;
          }
          setLoading(false);
          onClose({ saved: data.transactions.length > 0 });
        })
        .catch(() => {
          setLoading(false);
        });
    });

  return (
    <div className={classes.overlay}>
      {loading ? (
        <Loading />
      ) : (
        <form
          onSubmit={(event) => {
            event.preventDefault();
            fetchNewTransactions();
          }}
        >
          {!otpField && (
            <>
              <label className={classes.label}>
                E-mail{' '}
                <input
                  type="email"
                  onChange={(event) => {
                    setEmail(event.target.value);
                  }}
                />
              </label>
              <label className={classes.label}>
                Password{' '}
                <input
                  type="password"
                  onChange={(event) => {
                    setPassword(event.target.value);
                  }}
                />
              </label>
            </>
          )}
          {otpField && (
            <label className={classes.label}>
              OTP{' '}
              <input
                type="text"
                onChange={(event) => {
                  setOtp(event.target.value);
                }}
              />
            </label>
          )}
          <button className={classes.submit} disabled={loading}>
            {otpField ? 'Obter movimentos' : 'Login'}
          </button>
          <button
            className={classes.submit}
            disabled={loading}
            type="button"
            onClick={onClose}
          >
            Cancelar
          </button>
        </form>
      )}
    </div>
  );
};

export default UpdateCoverflexModal;
