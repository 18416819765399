import React, { useMemo, useState, useEffect } from 'react';
import classnames from 'classnames';
import moment from 'moment';
import 'moment/locale/pt';
import 'chartjs-adapter-moment';
import {
  Chart as ChartJS,
  CategoryScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  TimeScale,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

import useRequest from 'utils/useRequest';
import Loading from 'utils/Loading';
import Error from 'utils/Error';

import classes from './Data.module.scss';

ChartJS.register(
  CategoryScale,
  TimeScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend
);

const yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
const currentYear = yesterday.getFullYear();
const dayInYear = Math.floor(
  (yesterday - new Date(currentYear, 0, 0)) / (1000 * 60 * 60 * 24)
);

const options = [
  {
    label: '1m',
    timespanValue: 1,
    timespanPeriod: 'months',
    showAnnual: false,
  },
  {
    label: '6m',
    timespanValue: 6,
    timespanPeriod: 'months',
    showAnnual: false,
  },
  {
    label: '1a',
    timespanValue: 1,
    timespanPeriod: 'years',
    showAnnual: false,
  },
  {
    label: '5a',
    timespanValue: 5,
    timespanPeriod: 'years',
    showAnnual: true,
  },
  {
    label: '10a',
    timespanValue: 10,
    timespanPeriod: 'years',
    showAnnual: true,
  },
  {
    label: currentYear,
    timespanValue: dayInYear,
    timespanPeriod: 'days',
    showAnnual: false,
  },
  {
    label: 'Máximo',
    showAnnual: true,
  },
];

const Stocks = ({ ticker, currency }) => {
  const [firstLoad, setFirstLoad] = useState(false);
  const [timespanValue, setTimespanValue] = useState(dayInYear);
  const [timespanPeriod, setTimespanPeriod] = useState('days');
  const [showAnnual, setShowAnnual] = useState(false);
  const [hideYTD, setHideYTD] = useState(false);

  const { data, loading, error } = useRequest({
    url: `/proxy?url=https://quotes.ifra.pt/stock_${ticker}.json`,
    method: 'GET',
  });

  const chartData = useMemo(() => {
    if (data) {
      let dates = [];
      let labels = [];
      let dataset = [];
      const begin =
        timespanValue && timespanPeriod
          ? moment()
              .startOf('day')
              .subtract(timespanValue, timespanPeriod)
              .valueOf()
          : undefined;
      const days = Object.keys(data);
      const availableDays = days.reduce((acc, item, index) => {
        if (
          moment(item, 'YYYY-MM-DD').startOf('day').valueOf() >= begin ||
          !begin
        ) {
          if (!acc.length && days[index - 1]) {
            acc.push(days[index - 1]);
          }
          acc.push(item);
        }
        return acc;
      }, []);
      const points = availableDays.length > 500 ? 500 : availableDays.length;
      const dayStep = parseInt(availableDays.length / points);
      availableDays.forEach((element, index) => {
        if (index % dayStep === 0 && data[element]) {
          labels.push(element);
          dates.push(element);
          dataset.push(data[element]);
        }
      });
      return {
        labels,
        dates,
        dataset,
      };
    }
    return undefined;
  }, [data, timespanValue, timespanPeriod]);

  useEffect(() => {
    setFirstLoad(false);
  }, [ticker]);

  useEffect(() => {
    if (chartData && !firstLoad) {
      if (chartData.dates.length < 30) {
        setTimespanValue(1);
        setTimespanPeriod('years');
        setShowAnnual(true);
      }
      if (chartData.dates.length < 2) {
        setHideYTD(true);
      }
      setFirstLoad(true);
    }
  }, [firstLoad, chartData]);

  if (error) {
    return (
      <div
        style={{
          height: '400px',
          display: 'flex',
          alignItem: 'center',
          justifyContent: 'center',
        }}
      >
        <Error />
      </div>
    );
  }

  if (!chartData || loading) {
    return (
      <div
        style={{
          height: '400px',
          display: 'flex',
          alignItem: 'center',
          justifyContent: 'center',
        }}
      >
        <Loading />
      </div>
    );
  }

  const change = (
    (chartData.dataset[chartData.dataset.length - 1] / chartData.dataset[0] -
      1) *
    100
  ).toFixed(2);

  const annualRate =
    showAnnual &&
    (
      100 *
      (Math.pow(
        1 + change / 100,
        1 / moment().diff(chartData.dates[0], 'years', true)
      ) -
        1)
    ).toFixed(2);

  return (
    <div style={{ height: '400px' }}>
      <div className={classes.buttons}>
        <span className={classes.options}>
          {options.map((option) => {
            if (hideYTD && option.label === currentYear) {
              return null;
            }
            return (
              <button
                key={option.timespanValue + option.timespanPeriod}
                className={classnames({
                  [classes.selected]:
                    timespanValue === option.timespanValue &&
                    timespanPeriod === option.timespanPeriod,
                })}
                onClick={() => {
                  setTimespanValue(option.timespanValue);
                  setTimespanPeriod(option.timespanPeriod);
                  setShowAnnual(option.showAnnual);
                }}
              >
                {option.label}
              </button>
            );
          })}
        </span>
      </div>
      <div className={classes.change}>
        Ganho/perda:{' '}
        <span
          className={classnames({
            [classes.positive]: change > 0,
            [classes.negative]: change < 0,
            [classes.neutral]: change === 0,
          })}
        >
          {!showAnnual ? (
            <>
              {change > 0 && '+'}
              {new Intl.NumberFormat('pt-PT').format(change)}%
            </>
          ) : (
            <>
              {annualRate > 0 ? '+' : ''}
              {new Intl.NumberFormat('pt-PT').format(annualRate)}% por ano
            </>
          )}
        </span>
      </div>
      <div style={{ height: '300px' }}>
        <Line
          data={{
            labels: chartData.labels,
            datasets: [
              {
                data: chartData.dataset,
                borderColor: '#8031A7',
                backgroundColor: '#8031A7',
              },
            ],
          }}
          options={{
            maintainAspectRatio: false,
            borderWidth: 2,
            tension: 0,
            interaction: {
              intersect: false,
              mode: 'index',
            },
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                callbacks: {
                  title: (context) => chartData.dates[context[0].dataIndex],
                  label: (context) => {
                    return new Intl.NumberFormat('pt-PT', {
                      style: 'currency',
                      currency,
                    }).format(context.parsed.y);
                  },
                },
              },
            },
            pointRadius: 0,
            scales: {
              xAxis: {
                type: 'time',
                time: {
                  unit: chartData.dates.length < 30 ? 'day' : undefined,
                },
                grid: {
                  color: (context) =>
                    context.tick.label ? 'rgba(0,0,0,0.2)' : 'transparent',
                },
              },
              yAxis: {
                title: {
                  display: true,
                  text: `Valor (${currency})`,
                },
                grid: {
                  color: 'rgba(0,0,0,0.2)',
                },
              },
            },
          }}
        />
      </div>
    </div>
  );
};

export default Stocks;
