import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { keyBy, difference } from 'lodash';
import Validator from 'Validator';

import Article from 'components/Article';
import useRequest from 'utils/useRequest';
import Loading from 'utils/Loading';
import Error from 'utils/Error';

import { availablePortfolios } from './Simulator/constants';
import PersonalData from './Simulator/PersonalData';
import InvestmentData from './Simulator/InvestmentData';
import RetirementData from './Simulator/RetirementData';
import EstimationsData from './Simulator/EstimationsData';
import Output from './Simulator/Output';

import classes from './Simulator.module.scss';

const availablePortfoliosSlugs = availablePortfolios
  .map((item) => item.slug)
  .concat('fix')
  .concat('custom');

const availablePortfoliosBySlug = keyBy(availablePortfolios, 'slug');

const availablePureAssetPortfolios = availablePortfolios
  .filter((item) => item.pureAsset)
  .map((item) => item.slug);

const Simulator = () => {
  const {
    data: assets,
    loading: loadingAssets,
    error: errorAssets,
  } = useRequest({
    url: 'assets?Portfolio=true',
    method: 'GET',
  });

  const currentValue = useMemo(
    () =>
      Math.round(
        assets?.reduce(
          (acc, asset) =>
            (acc += asset.Quote
              ? asset.Quantity * asset.Quote
              : asset.Quantity),
          0
        ) / 1000
      ) * 1000,
    [assets]
  );

  const firstRun = useRef(true);

  const location = useLocation();
  const urlParams = useMemo(
    () => Object.fromEntries(new URLSearchParams(location.search)),
    [location.search]
  );
  const validationRules = {
    currentAge: 'required|integer|min:0',
    barista: 'required|boolean',
    baristaAge:
      urlParams.barista === '1'
        ? `required|integer|min:${urlParams.currentAge}|max:${urlParams.retirementAge}`
        : '',
    retirementAge: `required|integer|min:${urlParams.currentAge}|max:${urlParams.legalRetirementAge}`,
    legalRetirementAge: `required|integer|min:${urlParams.retirementAge}`,
    initialCapital: 'required|integer|min:0',
    monthBoost: 'required|integer|min:0',
    monthBoostIncrease: 'required|integer|min:0',
    withdrawalValue: 'required|integer|min:0',
    portfolio: `required|in:${availablePortfoliosSlugs.join()}`,
    customAllocations: urlParams.portfolio === 'custom' ? 'required|json' : '',
    expectedReturn:
      urlParams.portfolio === 'fix' ? 'required|integer|min:0' : '',
    portfolioSimulationStart:
      urlParams.portfolio !== 'fix' ? 'required|integer|min:0' : '',
    inflation: 'required|integer|min:0',
  };
  const validator = Validator.make(urlParams, validationRules);
  const validCustomAllocations = urlParams.customAllocations
    ? !validator.fails() &&
      !difference(
        Object.keys(JSON.parse(urlParams.customAllocations)),
        availablePureAssetPortfolios
      ).length &&
      !Object.values(JSON.parse(urlParams.customAllocations)).filter(
        (item) => !Number.isInteger(item)
      ).length &&
      !(
        Object.values(JSON.parse(urlParams.customAllocations)).reduce(
          (acc, item) => acc + item,
          0
        ) > 100
      )
    : true;

  const validUrlParams = !validator.fails() && validCustomAllocations;

  const params = {
    currentAge: validUrlParams
      ? Number(urlParams.currentAge)
      : new Date().getFullYear() - 1987,
    barista: validUrlParams ? Number(urlParams.barista) : 1,
    baristaAge: validUrlParams ? Number(urlParams.baristaAge) : 45,
    retirementAge: validUrlParams ? Number(urlParams.retirementAge) : 50,
    legalRetirementAge: validUrlParams
      ? Number(urlParams.legalRetirementAge)
      : 68,
    initialCapital: validUrlParams
      ? Number(urlParams.initialCapital)
      : currentValue,
    monthBoost: validUrlParams ? Number(urlParams.monthBoost) : 1100,
    monthBoostIncrease: validUrlParams
      ? Number(urlParams.monthBoostIncrease)
      : 5,
    withdrawalValue: validUrlParams ? Number(urlParams.withdrawalValue) : 2000,
    expectedReturn:
      validUrlParams && urlParams.expectedReturn
        ? Number(urlParams.expectedReturn)
        : 8,
    portfolio: validUrlParams ? urlParams.portfolio : 'custom',
    customAllocations:
      validUrlParams && urlParams.customAllocations
        ? JSON.parse(urlParams.customAllocations)
        : undefined,
    portfolioSimulationStart: validUrlParams
      ? Number(urlParams.portfolioSimulationStart)
      : 0,
    inflation: validUrlParams ? Number(urlParams.inflation) : 2,
  };

  const [currentAge, setCurrentAge] = useState(params.currentAge);
  const [barista, setBarista] = useState(params.barista);
  const [baristaAge, setBaristaAge] = useState(params.baristaAge);
  const [retirementAge, setRetirementAge] = useState(params.retirementAge);
  const [legalRetirementAge, setLegalRetirementAge] = useState(
    params.legalRetirementAge
  );

  const [initialCapital, setInitialCapital] = useState(params.initialCapital);
  useEffect(() => {
    setInitialCapital(params.initialCapital);
  }, [params.initialCapital]);
  const [monthBoost, setMonthBoost] = useState(params.monthBoost);
  const [monthBoostIncrease, setMonthBoostIncrease] = useState(
    params.monthBoostIncrease
  );

  const [withdrawalValue, setWithdrawalValue] = useState(
    params.withdrawalValue
  );

  const [expectedReturn, setExpectedReturn] = useState(params.expectedReturn);
  const [portfolio, setPortfolio] = useState(params.portfolio);
  const [customAllocations, setCustomAllocations] = useState(
    params.customAllocations ||
      availablePortfolios.reduce((acc, item) => {
        if (item.pureAsset) {
          acc[item.slug] = item.defaultAllocation;
        }
        return acc;
      }, {})
  );
  const monthsToSimulate = (legalRetirementAge - currentAge) * 12;

  const selectedPortfolio = useMemo(
    () =>
      portfolio === 'custom'
        ? {
            data: availablePortfoliosBySlug[
              Object.keys(customAllocations)[0]
            ].data.map((item, index) => {
              const value = Object.keys(customAllocations).reduce(
                (acc, slug) => ({
                  ...acc,
                  return:
                    acc.return +
                    availablePortfoliosBySlug[slug].data[index].return *
                      (customAllocations[slug] / 100),
                }),
                {
                  year: item.year,
                  month: item.month,
                  return: 0,
                }
              );
              return value;
            }),
          }
        : availablePortfolios.find((item) => item.slug === portfolio),
    [portfolio, customAllocations]
  );

  const trimmedSelectedPorfolio = useMemo(
    () =>
      selectedPortfolio && {
        ...selectedPortfolio,
        data: selectedPortfolio.data.slice(-1200),
      },
    [selectedPortfolio]
  );

  const [portfolioSimulationStart, setPortfolioSimulationStart] = useState(
    trimmedSelectedPorfolio &&
      params.portfolioSimulationStart <=
        trimmedSelectedPorfolio.data.length - monthsToSimulate - 1
      ? Number(params.portfolioSimulationStart)
      : 0
  );
  const [inflation, setInflation] = useState(params.inflation);

  useEffect(() => {
    if (
      trimmedSelectedPorfolio &&
      firstRun.current &&
      !urlParams.portfolioSimulationStart
    ) {
      setPortfolioSimulationStart(
        trimmedSelectedPorfolio.data.length - monthsToSimulate - 1
      );
    }
    if (trimmedSelectedPorfolio && !firstRun.current) {
      setPortfolioSimulationStart(
        trimmedSelectedPorfolio.data.length - monthsToSimulate - 1
      );
    }
    firstRun.current = false;
  }, [
    urlParams,
    currentAge,
    retirementAge,
    legalRetirementAge,
    trimmedSelectedPorfolio,
    monthsToSimulate,
  ]);

  if (loadingAssets) {
    return <Loading />;
  }

  if (errorAssets || !assets) {
    return <Error />;
  }

  return (
    <Article article={{ title: 'Simulador' }}>
      <p className={classes.warning}>
        Este simulador assume que não será necessário continuar a retirada a
        partir do momento em que se passa a ter acesso à pensão de velhice da
        Segurança Social.
      </p>
      <p className={classes.warning}>
        Assume ainda uma paridade grosseira entre o Euro e o Dólar para não o
        tornar demasiado complexo. Os retornos dos tipos de carteiras
        disponibilizadas são, na verdade, retornos em Dólar.
      </p>
      <form>
        <PersonalData
          currentAge={currentAge}
          setCurrentAge={setCurrentAge}
          barista={barista}
          setBarista={setBarista}
          baristaAge={baristaAge}
          setBaristaAge={setBaristaAge}
          retirementAge={retirementAge}
          setRetirementAge={setRetirementAge}
          legalRetirementAge={legalRetirementAge}
          setLegalRetirementAge={setLegalRetirementAge}
        />
        <InvestmentData
          initialCapital={initialCapital}
          setInitialCapital={setInitialCapital}
          monthBoost={monthBoost}
          setMonthBoost={setMonthBoost}
          monthBoostIncrease={monthBoostIncrease}
          setMonthBoostIncrease={setMonthBoostIncrease}
        />
        <RetirementData
          withdrawalValue={withdrawalValue}
          setWithdrawalValue={setWithdrawalValue}
        />
        <EstimationsData
          portfolio={portfolio}
          setPortfolio={setPortfolio}
          selectedPortfolio={trimmedSelectedPorfolio}
          customAllocations={customAllocations}
          setCustomAllocations={setCustomAllocations}
          expectedReturn={expectedReturn}
          setExpectedReturn={setExpectedReturn}
          monthsToSimulate={monthsToSimulate}
          portfolioSimulationStart={portfolioSimulationStart}
          setPortfolioSimulationStart={setPortfolioSimulationStart}
          inflation={inflation}
          setInflation={setInflation}
        />
        <Output
          currentAge={currentAge}
          barista={barista}
          baristaAge={baristaAge}
          retirementAge={retirementAge}
          legalRetirementAge={legalRetirementAge}
          initialCapital={initialCapital}
          monthBoost={monthBoost}
          monthBoostIncrease={monthBoostIncrease}
          withdrawalValue={withdrawalValue}
          expectedReturn={expectedReturn}
          portfolio={portfolio}
          customAllocations={customAllocations}
          portfolioSimulationStart={portfolioSimulationStart}
          inflation={inflation}
          selectedPortfolio={trimmedSelectedPorfolio}
          monthsToSimulate={monthsToSimulate}
        />
      </form>
    </Article>
  );
};

export default Simulator;
