import Homepage from 'views/Homepage';
import Archive from 'views/Archive';
import About from 'views/About';
import Simulator from 'views/Simulator';
import Resources from 'views/Resources';
import NetWorth from 'views/NetWorth';
import Portfolio from 'views/Portfolio';
import Transactions from 'views/Transactions';
import Post from 'views/Post';
import Data from 'views/Data';
import Unsubscribe from 'views/Unsubscribe';

const routes = [
  {
    path: '/',
    component: Homepage,
  },
  {
    title: 'Arquivo',
    path: '/arquivo',
    component: Archive,
  },
  {
    title: 'Sobre',
    path: '/sobre',
    component: About,
  },
  {
    title: 'Simulador',
    path: '/simulador',
    component: Simulator,
  },
  {
    title: 'Recursos',
    path: '/recursos',
    component: Resources,
  },
  {
    title: 'Património',
    path: '/patrimonio',
    component: NetWorth,
  },
  {
    title: 'Movimentos',
    path: '/movimentos',
    component: Transactions,
  },
  {
    title: 'Carteira',
    path: '/carteira',
    component: Portfolio,
  },
  {
    path: '/artigo/:slug',
    component: Post,
  },
  {
    path: '/indicadores',
    component: Data,
  },
  {
    title: 'Cancelar subscrição',
    path: '/cancelar-subscricao',
    component: Unsubscribe,
  },
];

export default routes;
