import React, { useState, useMemo, useEffect } from 'react';
import classnames from 'classnames';
import moment from 'moment';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

import classes from 'views/Data/Data.module.scss';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend
);

const yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
const currentYear = yesterday.getFullYear();
const daysInCurrentYear = Math.floor(
  (new Date() - new Date(currentYear, 0, 0)) / (1000 * 60 * 60 * 24)
);

const options = [
  {
    label: '1m',
    timespanValue: 1,
    timespanPeriod: 'months',
  },
  {
    label: '6m',
    timespanValue: 6,
    timespanPeriod: 'months',
  },
  {
    label: '1a',
    timespanValue: 1,
    timespanPeriod: 'years',
  },
  {
    label: currentYear,
    timespanValue: daysInCurrentYear,
    timespanPeriod: 'days',
  },
  {
    label: 'Máximo',
  },
];

const EvolutionChart = React.memo(({ data }) => {
  const [firstLoad, setFirstLoad] = useState(false);
  const [hideYTD, setHideYTD] = useState(false);
  const [timespanValue, setTimespanValue] = useState(daysInCurrentYear);
  const [timespanPeriod, setTimespanPeriod] = useState('days');

  const begin = useMemo(
    () =>
      timespanValue && timespanPeriod
        ? moment().subtract(timespanValue, timespanPeriod).valueOf()
        : undefined,
    [timespanValue, timespanPeriod]
  );

  const fullData = useMemo(() => {
    const days = moment().diff(
      begin || Object.keys(data.portfolioByDate)[0],
      'days'
    );
    let lastLabel = 0;
    return Object.entries(data.portfolioByDate).reduce(
      (acc, [date, info], index) => {
        const isMonthStart = moment(date).startOf('month').isSame(moment(date));
        if (index - lastLabel > days / 10 && isMonthStart) {
          lastLabel = index;
          acc.labels.push(moment(date).format('YYYY-MM'));
        } else {
          acc.labels.push('');
        }
        acc.dates.push(moment(date).format('YYYY-MM-DD'));
        acc.invested.push(
          info.invested + acc.invested[index - 1] || info.invested
        );
        acc.capital.push(info.total);
        acc.predicted.push(
          (info.invested + acc.predicted[index - 1] || info.invested) *
            Math.pow(1.06, 1 / 365)
        );
        return acc;
      },
      {
        labels: [],
        dates: [],
        invested: [],
        capital: [],
        predicted: [],
      }
    );
  }, [begin, data]);

  const chartData = useMemo(
    () =>
      fullData.dates.reduce(
        (acc, item, index) => {
          if (!begin || moment(item) >= begin) {
            acc.labels.push(fullData.labels[index]);
            acc.dates.push(fullData.dates[index]);
            acc.invested.push(fullData.invested[index]);
            acc.capital.push(fullData.capital[index]);
            acc.predicted.push(fullData.predicted[index]);
          }
          return acc;
        },
        {
          labels: [],
          dates: [],
          invested: [],
          capital: [],
          predicted: [],
        }
      ),
    [fullData, begin]
  );

  useEffect(() => {
    if (!firstLoad) {
      if (chartData.dates.length < 30) {
        setTimespanValue(1);
        setTimespanPeriod('years');
      }
      if (chartData.dates.length < 2) {
        setHideYTD(true);
      }
      setFirstLoad(true);
    }
  }, [firstLoad, chartData]);

  return (
    <>
      <h3>Evolução:</h3>
      <div className={classes.buttons}>
        <span className={classes.options}>
          {options.map((option) => {
            if (hideYTD && option.label === currentYear) {
              return null;
            }
            return (
              <button
                key={option.timespanValue + option.timespanPeriod}
                className={classnames({
                  [classes.selected]:
                    timespanValue === option.timespanValue &&
                    timespanPeriod === option.timespanPeriod,
                })}
                onClick={() => {
                  setTimespanValue(option.timespanValue);
                  setTimespanPeriod(option.timespanPeriod);
                }}
              >
                {option.label}
              </button>
            );
          })}
        </span>
      </div>
      <div style={{ height: '300px' }}>
        <Line
          data={{
            labels: chartData.labels,
            datasets: [
              {
                label: 'Investido',
                data: chartData.invested,
                borderColor: '#FFA400',
                borderWidth: 1,
                backgroundColor: '#FFA400',
              },
              {
                label: 'Capital acumulado',
                data: chartData.capital,
                borderColor: '#8031A7',
                borderWidth: 1,
                backgroundColor: '#8031A7',
              },
              {
                label: 'Capital previsto (6% por ano)',
                data: chartData.predicted,
                borderColor: '#2271b1',
                borderWidth: 1,
                backgroundColor: '#2271b1',
                hidden: true,
              },
            ],
          }}
          options={{
            animation: false,
            maintainAspectRatio: false,
            interaction: {
              intersect: false,
              mode: 'index',
            },
            plugins: {
              legend: {
                labels: {
                  boxWidth: 10,
                  boxHeight: 10,
                },
              },
              tooltip: {
                callbacks: {
                  title: (context) => chartData.dates[context[0].dataIndex],
                  label: (context) => {
                    let label = context.dataset.label || '';
                    if (label) {
                      label += ': ';
                    }
                    if (context.parsed.y !== null) {
                      label += new Intl.NumberFormat('pt-PT', {
                        style: 'currency',
                        currency: 'EUR',
                      }).format(context.parsed.y);
                    }
                    return label;
                  },
                },
              },
            },
            pointRadius: 0,
            scales: {
              xAxis: {
                ticks: {
                  autoSkip: false,
                },
                grid: {
                  color: (context) =>
                    context.tick.label ? 'rgba(0,0,0,0.2)' : 'transparent',
                },
              },
              yAxis: {
                grid: {
                  color: 'rgba(0,0,0,0.2)',
                },
              },
            },
          }}
        />
      </div>
    </>
  );
});

export default EvolutionChart;
