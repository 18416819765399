import React from 'react';

import Markdown from 'utils/Markdown';
import RecentPosts from 'components/RecentPosts';

import classes from './Article.module.scss';

const Article = ({ children, article, recentPosts }) => {
  return (
    <div>
      <article>
        <h1 className={classes.title}>{article.title}</h1>
        {article.date && (
          <time className={classes.date} dateTime={article.date}>
            {new Date(article.date).toLocaleDateString('pt-PT', {
              weekday: 'long',
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            })}
          </time>
        )}
        <Markdown content={article.body} />
        {children && <div className="article-body">{children}</div>}
      </article>
      {recentPosts && <RecentPosts ignore={article.id} />}
    </div>
  );
};

export default Article;
