import React from 'react';
import { marked } from 'marked';

const Markdown = ({ content }) => (
  <div
    className="markdown"
    dangerouslySetInnerHTML={{ __html: marked(content || '') }}
  ></div>
);

export default Markdown;
