import React from 'react';

import classes from './Loading.module.scss';

const Loading = () => (
  <article className={classes.loading}>
    <div className={classes.icon}>
      <i className="fas fa-spinner fa-pulse"></i>
    </div>
  </article>
);

export default Loading;
