import React from 'react';

import useRequest from 'utils/useRequest';
import Loading from 'utils/Loading';
import Error from 'utils/Error';
import Article from 'components/Article';

const Homepage = () => {
  const { data, loading, error } = useRequest({
    url: 'articles?_sort=Date:DESC&_limit=1',
    method: 'GET',
  });

  if (loading) {
    return <Loading />;
  }

  if (error || !data?.length > 0) {
    return <Error />;
  }

  return <Article article={data[0]} recentPosts />;
};

export default Homepage;
