export const financial = (x) => Number.parseFloat(x.toFixed(8));

export const balanceToClass = (balance) => {
  if (balance > 0) {
    return 'up';
  }
  if (balance < 0) {
    return 'down';
  }
  return 'neutral';
};
