import React from 'react';

import classes from '../Simulator.module.scss';

const RetirementData = ({ withdrawalValue, setWithdrawalValue }) => (
  <fieldset className={classes.fieldset}>
    <legend>Fase de retirada</legend>
    <label className={classes.label}>
      Rendimento bruto pretendido aos preços de hoje (€){' '}
      <input
        type="number"
        min="0"
        size="7"
        value={withdrawalValue}
        onChange={(e) => setWithdrawalValue(parseInt(e.target.value))}
      />
    </label>
  </fieldset>
);

export default RetirementData;
