import React, { useState, useEffect } from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Cookies from 'js-cookie';

import 'normalize.css';
import 'Toasts.scss';
import 'App.scss';

import { useStateValue } from 'state/State';
import routes from 'routes';
import Header from 'partials/Header';
import Footer from 'partials/Footer';
import useRequest from 'utils/useRequest';
import RouteWithTitle from 'utils/RouteWithTitle';
import Error from 'utils/Error';
import Loading from 'utils/Loading';

const App = () => {
  const [loading, setLoading] = useState(true);
  const [{ user }, dispatch] = useStateValue();

  const {
    data,
    loading: requesting,
    request,
  } = useRequest({
    url: 'users/me',
    method: 'GET',
    callback: true,
  });

  useEffect(() => {
    if (data) {
      dispatch({
        type: 'user.login',
        payload: data,
      });
    }
  }, [data, dispatch]);

  useEffect(() => {
    const token = Cookies.get('AUTH-TOKEN');
    if (!user && token) {
      request();
    } else {
      setLoading(false);
    }
  }, [user, request]);

  if (loading || requesting) {
    return <Loading />;
  }

  return (
    <BrowserRouter>
      <Header />
      <main>
        <Switch>
          {routes.map((route) => (
            <RouteWithTitle
              key={route.path}
              title={route.title}
              exact={!!route.path}
              {...route}
            />
          ))}
          <RouteWithTitle
            title={'404'}
            component={() => <Error error={404} />}
          />
        </Switch>
      </main>
      <Footer />
      <ToastContainer />
    </BrowserRouter>
  );
};

export default App;
