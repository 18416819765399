import React, { useState, useMemo, useEffect } from 'react';
import classnames from 'classnames';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import moment from 'moment';

import useRequest from 'utils/useRequest';

import classes from 'views/Data/Data.module.scss';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Legend,
  Tooltip
);

const yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
const currentYear = yesterday.getFullYear();
const daysInCurrentYear = Math.floor(
  (new Date() - new Date(currentYear, 0, 0)) / (1000 * 60 * 60 * 24)
);

const options = [
  {
    label: '1m',
    timespanValue: 1,
    timespanPeriod: 'months',
  },
  {
    label: '6m',
    timespanValue: 6,
    timespanPeriod: 'months',
  },
  {
    label: '1a',
    timespanValue: 1,
    timespanPeriod: 'years',
  },
  {
    label: currentYear,
    timespanValue: daysInCurrentYear,
    timespanPeriod: 'days',
  },
  {
    label: 'Máximo',
  },
];

const TWRChart = React.memo(({ data }) => {
  const [firstLoad, setFirstLoad] = useState(false);
  const [hideYTD, setHideYTD] = useState(false);

  const { data: sp500Data } = useRequest({
    url: `/proxy?url=https://quotes.ifra.pt/etf_IE00B5BMR087-EUR.json`,
    method: 'GET',
  });

  const { data: bondsData } = useRequest({
    url: `/proxy?url=https://quotes.ifra.pt/etf_IE00B1FZSC47-EUR.json`,
    method: 'GET',
  });

  const { data: goldData } = useRequest({
    url: `/proxy?url=https://quotes.ifra.pt/etf_IE00B579F325-EUR.json`,
    method: 'GET',
  });

  const [timespanValue, setTimespanValue] = useState(daysInCurrentYear);
  const [timespanPeriod, setTimespanPeriod] = useState('days');

  const begin = useMemo(
    () =>
      timespanValue && timespanPeriod
        ? moment()
            .startOf('day')
            .subtract(timespanValue, timespanPeriod)
            .valueOf()
        : undefined,
    [timespanValue, timespanPeriod]
  );

  const yearsSinceInception = moment().diff(data.Inception, 'years', true);
  const annualRate = Number(
    (
      100 *
      (Math.pow(1 + data.portfolioTWR / 100, 1 / yearsSinceInception) - 1)
    ).toFixed(2)
  );

  const fullData = useMemo(() => {
    const days = moment().diff(
      begin || Object.keys(data.portfolioByDate)[0],
      'days'
    );
    let lastLabel = 0;
    return Object.entries(data.portfolioByDate).reduce(
      (acc, [date], index) => {
        // portfolio value
        const portfolioTWR =
          100 *
          (Object.values(data.portfolioByDate).reduce(
            (acc, item, innerIndex) => {
              if (innerIndex > index || !item.dailyReturn) {
                return acc;
              }
              return acc * (1 + item.dailyReturn / 100);
            },
            1
          ) -
            1);
        acc.data.push(portfolioTWR);
        acc.allDates.push(moment(date).format('YYYY-MM-DD'));

        if (!begin || moment(date) >= begin) {
          if (!acc.firstChartIndex) {
            acc.firstChartIndex = index;
          }
          const isMonthStart = moment(date)
            .startOf('month')
            .isSame(moment(date));
          if (index - lastLabel > days / 10 && isMonthStart) {
            lastLabel = index;
            acc.labels.push(moment(date).format('YYYY-MM'));
          } else {
            acc.labels.push('');
          }
          acc.dates.push(moment(date).format('YYYY-MM-DD'));

          const inceptionDate = begin
            ? moment(begin).format('YYYY-MM-DD')
            : data.Inception;

          // Portfolio data
          acc.chartData.push(portfolioTWR - acc.data[acc.firstChartIndex]);

          // S&P 500 data
          acc.sp500Data.push(
            sp500Data?.[date]
              ? 100 * (sp500Data[date] / sp500Data[inceptionDate] - 1)
              : undefined
          );

          // TIPS data
          acc.bondsData.push(
            bondsData?.[date]
              ? 100 * (bondsData[date] / bondsData[inceptionDate] - 1)
              : undefined
          );

          // Gold data
          acc.goldData.push(
            goldData?.[date]
              ? 100 * (goldData[date] / goldData[inceptionDate] - 1)
              : undefined
          );
        }

        return acc;
      },
      {
        data: [], // all time
        allDates: [],
        firstChartIndex: undefined,
        labels: [],
        dates: [],
        chartData: [],
        sp500Data: [],
        bondsData: [],
        goldData: [],
      }
    );
  }, [begin, data, sp500Data, bondsData, goldData]);

  const allTimeHigh = useMemo(
    () =>
      fullData.data.reduce(
        (acc, item, index) => {
          if (item > acc.value) {
            acc.value = item;
            acc.date = fullData.allDates[index];
          }
          return acc;
        },
        {
          date: fullData.dates[0],
          value: 0,
        }
      ),
    [fullData]
  );

  const ATHDaysAgo = moment().diff(allTimeHigh.date, 'days', false);
  const currentDrawdown = allTimeHigh.value - data.portfolioTWR;

  useEffect(() => {
    if (!firstLoad) {
      if (fullData.dates.length < 30) {
        setTimespanValue(1);
        setTimespanPeriod('years');
      }
      if (fullData.dates.length < 2) {
        setHideYTD(true);
      }
      setFirstLoad(true);
    }
  }, [firstLoad, fullData]);

  return (
    <>
      <h3>
        <a
          href="https://www.investopedia.com/terms/t/time-weightedror.asp"
          target="_blank"
          rel="noreferrer"
        >
          Retorno ponderado pelo tempo
        </a>
        :
      </h3>
      <div className={classes.buttons}>
        <span className={classes.options}>
          {options.map((option) => {
            if (hideYTD && option.label === currentYear) {
              return null;
            }
            return (
              <button
                key={option.timespanValue + option.timespanPeriod}
                className={classnames({
                  [classes.selected]:
                    timespanValue === option.timespanValue &&
                    timespanPeriod === option.timespanPeriod,
                })}
                onClick={() => {
                  setTimespanValue(option.timespanValue);
                  setTimespanPeriod(option.timespanPeriod);
                }}
              >
                {option.label}
              </button>
            );
          })}
        </span>
      </div>
      <div style={{ height: '300px' }}>
        <Line
          data={{
            labels: fullData.labels,
            datasets: [
              {
                label: 'Carteira',
                data: fullData.chartData,
                borderColor: '#50a684',
                borderWidth: 1,
                backgroundColor: '#50a684',
              },
              {
                label: 'iShares Core S&P 500 UCITS ETF (Acc)',
                data: fullData.sp500Data,
                borderColor: '#e1523e',
                borderWidth: 1,
                backgroundColor: '#e1523e',
                hidden: true,
              },
              {
                label: 'iShares USD TIPS UCITS ETF USD (Acc)',
                data: fullData.bondsData,
                borderColor: '#2271b1',
                borderWidth: 1,
                backgroundColor: '#2271b1',
                hidden: true,
              },
              {
                label: 'Invesco Physical Gold A',
                data: fullData.goldData,
                borderColor: '#ffa400',
                borderWidth: 1,
                backgroundColor: '#ffa400',
                hidden: true,
              },
            ],
          }}
          options={{
            animation: false,
            maintainAspectRatio: false,
            interaction: {
              intersect: false,
              mode: 'index',
            },
            plugins: {
              legend: {
                labels: {
                  boxWidth: 10,
                  boxHeight: 10,
                },
              },
              tooltip: {
                callbacks: {
                  title: (context) => fullData.dates[context[0].dataIndex],
                  label: (context) => {
                    let label = context.dataset.label || '';
                    if (label) {
                      label += ': ';
                    }
                    if (context.parsed.y !== null) {
                      label += `${Math.round(context.parsed.y * 100) / 100}%`;
                    }
                    return label;
                  },
                },
              },
            },
            pointRadius: 0,
            scales: {
              xAxis: {
                ticks: {
                  autoSkip: false,
                },
                grid: {
                  color: (context) =>
                    context.tick.label ? 'rgba(0,0,0,0.2)' : 'transparent',
                },
              },
              yAxis: {
                grid: {
                  color: 'rgba(0,0,0,0.2)',
                },
              },
            },
          }}
        />
      </div>
      <p>
        <strong>Valor actual:</strong>{' '}
        {Number(data.portfolioTWR.toFixed(2)).toLocaleString('pt-PT')}% (
        {annualRate > 0 ? '+' : ''}
        {annualRate.toLocaleString('pt-PT')}% por ano)
        {currentDrawdown > 0 && (
          <>
            <br />
            <strong>Declínio desde o máximo histórico:</strong>{' '}
            {Number(currentDrawdown.toFixed(2)).toLocaleString('pt-PT')}%
            <br />
            <strong>Tempo decorrido desdo o máximo histórico:</strong>{' '}
            {ATHDaysAgo} {ATHDaysAgo > 1 ? 'dias' : 'dia'}
          </>
        )}
      </p>
    </>
  );
});

export default TWRChart;
