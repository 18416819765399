import { useState } from 'react';

import useRequest from 'utils/useRequest';

import classes from './Modal.module.scss';

const CategoryModal = ({ categories, transaction, onClose }) => {
  const [category, setCategory] = useState(transaction.category?.id);
  const { loading: submitting, request } = useRequest({
    url: `/bank-transactions/${transaction.id}`,
    method: 'PUT',
    callback: true,
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    request({
      data: {
        category,
      },
    }).then(() => {
      onClose({ saved: true });
    });
    // TODO: handle error
  };

  return (
    <div className={classes.overlay}>
      <form
        onSubmit={(event) => {
          event.persist();
          handleSubmit(event);
        }}
      >
        <label className={classes.label}>
          <span>Categoria</span>
          <select
            type="text"
            name="category"
            required
            value={category}
            onChange={(e) => {
              setCategory(e.target.value);
            }}
          >
            <option value="0">❔ Sem categoria</option>
            {categories.map((category) => (
              <option value={category.id} key={category.id}>
                {category.emoji} {category.title}
              </option>
            ))}
          </select>
        </label>
        <button className={classes.submit} disabled={submitting}>
          Gravar
        </button>
        <button
          className={classes.submit}
          disabled={submitting}
          type="button"
          onClick={onClose}
        >
          Cancelar
        </button>
      </form>
    </div>
  );
};

export default CategoryModal;
