import { useEffect } from 'react';
import Cookies from 'js-cookie';

import useRequest from 'utils/useRequest';

import classes from './LoginModal.module.scss';

const LoginModal = ({ onClose }) => {
  const {
    data,
    loading: submitting,
    error,
    request,
  } = useRequest({
    url: 'auth/local',
    method: 'POST',
    callback: true,
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    request({ data: new FormData(event.target) });
  };

  useEffect(() => {
    if (data) {
      Cookies.set('AUTH-TOKEN', data.jwt, {
        expires: 15,
        sameSite: 'strict',
      });
      window.location.reload();
    }
  }, [data]);

  return (
    <div className={classes.overlay}>
      <form
        onSubmit={(event) => {
          event.persist();
          handleSubmit(event);
        }}
      >
        <label className={classes.label}>
          <span>Nome de utilizador</span>
          <input type="text" name="identifier" required />
        </label>
        <label className={classes.label}>
          <span>Palavra-passe</span>
          <input type="password" name="password" required />
        </label>
        {error && (
          <p className={classes.error}>
            Credenciais erradas (ou o servidor está em baixo 🥸)
          </p>
        )}
        <button className={classes.submit} disabled={submitting}>
          Entrar
        </button>
        <button
          className={classes.submit}
          disabled={submitting}
          type="button"
          onClick={onClose}
        >
          Cancelar
        </button>
      </form>
    </div>
  );
};

export default LoginModal;
