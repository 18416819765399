import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

export const setBearerToken = (token) => {
  if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }
};

setBearerToken(Cookies.get('AUTH-TOKEN'));

const useRequest = ({
  url: defaultUrl,
  method: defaultMethod = 'GET',
  payload,
  callback = false,
}) => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(!callback ? true : false);
  const [error, setError] = useState(false);

  const request = useCallback(
    ({
      method = defaultMethod,
      url = defaultUrl,
      data = {},
      params = {},
    } = {}) => {
      setData();
      setError(false);
      setLoading(true);
      return axios({
        method,
        url,
        data,
        params,
      })
        .then((response) => {
          setData(response.data);
          if (callback) {
            return response.data;
          }
        })
        .catch((error) => {
          setError(true);
          if (callback) {
            throw error;
          }
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [defaultUrl, defaultMethod, callback]
  );

  useEffect(() => {
    if (!callback) {
      request(payload);
    }
  }, [request, payload, callback]);

  return {
    data,
    loading,
    error,
    request,
  };
};

export default useRequest;
