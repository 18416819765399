import desert from './portfolios/gyroscopic-investing-desert-portfolio.json';
import technology from './portfolios/technology.json';
import usStocks from './portfolios/us-stocks.json';
import stocks20Bonds80 from './portfolios/stocks-bonds-20-80.json';
import simplePath from './portfolios/simple-path-to-wealth.json';
import goldenButterfly from './portfolios/golden-butterfly.json';
import gold from './portfolios/gold.json';
import tips from './portfolios/us-inflation-protection.json';
import bnd from './portfolios/total-bond-us.json';
import rdaw from './portfolios/ray-dalio-all-weather.json';
import sp from './portfolios/simplified-permanent-portfolio.json';

export const availablePortfolios = [
  {
    slug: 'technology',
    name: 'Technology',
    risk: 'very_high',
    etfs: 1,
    data: technology,
    source: 'http://www.lazyportfolioetf.com/allocation/technology/',
  },
  {
    slug: 'us_stocks',
    name: 'US Stocks',
    risk: 'very_high',
    etfs: 1,
    data: usStocks,
    source: 'http://www.lazyportfolioetf.com/allocation/us-stocks/',
    pureAsset: true,
    defaultAllocation: 60,
  },
  {
    slug: 'gold',
    name: 'Gold',
    risk: 'very_high',
    etfs: 1,
    data: gold,
    source: 'http://www.lazyportfolioetf.com/allocation/gold/',
    pureAsset: true,
    defaultAllocation: 20,
  },
  {
    slug: 'simple_path',
    name: 'JL Collins Simple Path to Wealth',
    risk: 'high',
    etfs: 2,
    data: simplePath,
    source: 'http://www.lazyportfolioetf.com/allocation/simple-path-to-wealth/',
  },
  {
    slug: 'golden_butterfly',
    name: 'Golden Butterfly',
    risk: 'high',
    etfs: 5,
    data: goldenButterfly,
    source: 'http://www.lazyportfolioetf.com/allocation/golden-butterfly/',
  },
  {
    slug: 'desert',
    name: 'Gyroscopic Investing Desert',
    risk: 'medium',
    etfs: 3,
    data: desert,
    source:
      'http://www.lazyportfolioetf.com/allocation/gyroscopic-investing-desert-portfolio/',
  },
  {
    slug: 'rdaw',
    name: 'Ray Dalio All Weather',
    risk: 'medium',
    etfs: 5,
    data: rdaw,
    source: 'https://www.lazyportfolioetf.com/allocation/ray-dalio-all-weather/',
  },
  {
    slug: 'sp',
    name: 'Simplified Permanent',
    risk: 'medium',
    etfs: 3,
    data: sp,
    source: 'https://www.lazyportfolioetf.com/allocation/simplified-permanent-portfolio/',
  },
  {
    slug: 'stocks20_bonds80',
    name: 'Stocks/Bonds 20/80',
    risk: 'low',
    etfs: 2,
    data: stocks20Bonds80,
    source: 'http://www.lazyportfolioetf.com/allocation/stocks-bonds-20-80/',
  },
  {
    slug: 'tips',
    name: 'US Inflation Protection',
    risk: 'low',
    etfs: 1,
    data: tips,
    source:
      'http://www.lazyportfolioetf.com/allocation/us-inflation-protection/',
  },
  {
    slug: 'bnd',
    name: 'Total Bond US',
    risk: 'low',
    etfs: 1,
    data: bnd,
    source: 'http://www.lazyportfolioetf.com/allocation/total-bond-us/',
    pureAsset: true,
    defaultAllocation: 20,
  },
];

export const riskLabels = {
  low: 'Baixo',
  medium: 'Médio',
  high: 'Alto',
  very_high: 'Muito alto',
};
