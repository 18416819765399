import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import useRequest from 'utils/useRequest';
import Loading from 'utils/Loading';
import Error from 'utils/Error';
import Article from 'components/Article';

const Post = () => {
  const { slug } = useParams();
  const { data, loading, error } = useRequest({
    url: `articles?slug=${slug}`,
    method: 'GET',
  });

  useEffect(() => {
    if (data?.[0]) {
      document.title = `${process.env.REACT_APP_NAME} | ${data[0].title}`;
    }
  }, [data]);

  if (loading) {
    return <Loading />;
  }

  if (error || !data) {
    return <Error />;
  }

  if (data.length === 0) {
    return <Error error={404} />;
  }

  return <Article article={data[0]} recentPosts />;
};

export default Post;
