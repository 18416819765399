import React, { useMemo } from 'react';
import { keyBy } from 'lodash';

import { financial, balanceToClass } from 'utils/functions';

import classes from '../Portfolio.module.scss';

const typesLabels = {
  stock: { label: 'Acções', emoji: '📈' },
  bond: { label: 'Obrigações', emoji: '💵' },
  gold: { label: 'Ouro', emoji: '🧈' },
  ppr: { label: 'PPR', emoji: '💰' },
};

const PLData = ({ assets, transactions }) => {
  const assetsByInternalName = useMemo(() => keyBy(assets, 'InternalName'), [assets]);

  const cryptoBalance = useMemo(
    () =>
      assets.reduce((acc, asset) => {
        if (asset.Type === 'crypto') {
          acc = acc + asset.Quote * asset.Quantity;
        }
        return acc;
      }, 0),
    [assets]
  );

  const dataByTicker = useMemo(() => {
    return transactions?.reduce(
      (acc, transaction) => {
        if (!transaction.Ticker) {
          return acc;
        }

        // Aggregate cryptos because the transactions are not accurate individually
        if (transaction.AssetType === 'crypto') {
          if (transaction.Type === 'buy') {
            acc.crypto = financial(acc.crypto - transaction.TotalValue);
          }
          if (transaction.Type === 'sell') {
            acc.crypto = financial(acc.crypto + transaction.TotalValue);
          }
          if (transaction.Type === 'dividend') {
            acc.crypto = financial(acc.crypto + transaction.TotalValue);
          }
          return acc;
        }

        if (
          ['buy', 'sell', 'dividend'].includes(transaction.Type) &&
          !acc[transaction.Ticker]
        ) {
          acc[transaction.Ticker] = {
            shares: 0,
            balance: assetsByInternalName[transaction.Ticker]
              ? assetsByInternalName[transaction.Ticker].Quote *
                assetsByInternalName[transaction.Ticker].Quantity
              : 0,
            type: transaction.AssetType,
          };
        }

        if (transaction.Type === 'buy') {
          acc[transaction.Ticker].shares = financial(
            acc[transaction.Ticker].shares + transaction.Shares
          );
          acc[transaction.Ticker].balance = financial(
            acc[transaction.Ticker].balance - transaction.TotalValue
          );
        }
        if (transaction.Type === 'sell') {
          acc[transaction.Ticker].shares = financial(
            acc[transaction.Ticker].shares - transaction.Shares
          );
          acc[transaction.Ticker].balance = financial(
            acc[transaction.Ticker].balance + transaction.TotalValue
          );
        }
        if (transaction.Type === 'dividend') {
          acc[transaction.Ticker].balance = financial(
            acc[transaction.Ticker].balance + transaction.TotalValue
          );
        }

        return acc;
      },
      { crypto: cryptoBalance }
    );
  }, [transactions, assetsByInternalName, cryptoBalance]);

  const closedPositions = useMemo(
    () =>
      dataByTicker
        ? Object.entries(dataByTicker).filter(
            ([_, value]) => value.shares === 0
          )
        : [],
    [dataByTicker]
  );

  const activePositions = useMemo(
    () =>
      dataByTicker
        ? Object.entries(dataByTicker).filter(([_, value]) => value.shares > 0)
        : [],
    [dataByTicker]
  );

  return (
    <>
      {closedPositions.length > 0 && (
        <>
          <h3>Posições fechadas:</h3>
          <table className={classes.assetsTable}>
            <thead>
              <tr>
                <th className={classes.name}>Activo</th>
                <th className={classes.pl}>Ganho/perda</th>
              </tr>
            </thead>
            <tbody>
              {closedPositions.map(([key, value]) => (
                <tr key={key}>
                  <th className={classes.name}>
                    <b title={typesLabels[value.type].label}>
                      {typesLabels[value.type].emoji}
                    </b>{' '}
                    {key}
                  </th>
                  <td className={classes.pl}>
                    <strong className={classes[balanceToClass(value.balance)]}>
                      {Number(value.balance.toFixed(2)).toLocaleString('pt-PT')}{' '}
                      €
                    </strong>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <br />
        </>
      )}
      {activePositions.length > 0 && (
        <>
          <h3>Posições abertas:</h3>
          <table className={classes.assetsTable}>
            <thead>
              <tr>
                <th className={classes.name}>Activo</th>
                <th className={classes.pl}>Ganho/perda</th>
              </tr>
            </thead>
            <tbody>
              {activePositions.map(([key, value]) => (
                <tr key={key}>
                  <th className={classes.name}>
                    <b title={typesLabels[value.type].label}>
                      {typesLabels[value.type].emoji}
                    </b>{' '}
                    {key}
                  </th>
                  <td className={classes.pl}>
                    <strong className={classes[balanceToClass(value.balance)]}>
                      {Number(value.balance.toFixed(2)).toLocaleString('pt-PT')}{' '}
                      €
                    </strong>
                  </td>
                </tr>
              ))}
              {dataByTicker.crypto !== 0 && (
                <tr>
                  <th className={classes.name}>
                    <b>₿</b> Criptomoedas
                  </th>
                  <td className={classes.pl}>
                    <strong
                      className={classes[balanceToClass(dataByTicker.crypto)]}
                    >
                      {Number(dataByTicker.crypto.toFixed(2)).toLocaleString(
                        'pt-PT'
                      )}{' '}
                      €
                    </strong>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </>
      )}
    </>
  );
};

export default PLData;
