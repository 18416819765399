import React, { useState, useMemo } from 'react';
import classnames from 'classnames';
import { Chart, ArcElement, Tooltip } from 'chart.js';

import { balanceToClass } from 'utils/functions';
import useRequest from 'utils/useRequest';
import Loading from 'utils/Loading';
import Error from 'utils/Error';
import Article from 'components/Article';

import EvolutionChart from './Portfolio/EvolutionChart';
import TWR from './Portfolio/TWR';
import PLData from './Portfolio/PLData';
import PortfolioByAssets from './Portfolio/PortfolioByAssets';
import PortfolioByType from './Portfolio/PortfolioByType';

import classes from './Portfolio.module.scss';

Chart.register(ArcElement, Tooltip);

const typesNames = {
  stock: 'Acções',
  bond: 'Obrigações',
  gold: 'Ouro',
  ppr: 'PPR',
  crypto: 'Criptomoedas',
  cash: 'Dinheiro',
};

const typesOrder = Object.keys(typesNames).reduce((acc, item, index) => {
  acc[item] = index;
  return acc;
}, {});

const Portfolio = () => {
  const {
    data: portfolio,
    loading: loadingPortfolio,
    error: errorPortfolio,
  } = useRequest({
    url: 'portfolio',
    method: 'GET',
  });

  const {
    data: assets,
    loading: loadingAssets,
    error: errorAssets,
  } = useRequest({
    url: 'assets?Portfolio=true',
    method: 'GET',
  });

  const {
    data: transactions,
    loading: loadingTransactions,
    error: errorTransactions,
  } = useRequest({
    url: 'transactions',
    method: 'GET',
  });

  const [view, setView] = useState('types');

  const currentValue = useMemo(
    () =>
      assets?.reduce(
        (acc, asset) =>
          (acc += asset.Quote ? asset.Quantity * asset.Quote : asset.Quantity),
        0
      ),
    [assets]
  );

  if (loadingAssets || loadingPortfolio || loadingTransactions) {
    return <Loading />;
  }

  if (
    errorAssets ||
    errorPortfolio ||
    errorTransactions ||
    !assets ||
    !portfolio ||
    !transactions
  ) {
    return <Error />;
  }

  return (
    <Article article={{ title: 'Carteira' }}>
      <h2>Dados globais</h2>
      <p>
        <strong>Início do investimento:</strong> {portfolio.Inception}
        <br />
        <strong>Total investido:</strong>{' '}
        {portfolio.totalInvested.toLocaleString('pt-PT')} €
        <br />
        {portfolio.totalWithdrawn > 0 && (
          <>
            <strong>Total retirado:</strong>{' '}
            {portfolio.totalWithdrawn.toLocaleString('pt-PT')} €
            <br />
          </>
        )}
        <strong>Valor actual:</strong>{' '}
        {Number(currentValue.toFixed(2)).toLocaleString('pt-PT')} €
        <br />
        <strong>Ganho/perda:</strong>{' '}
        <span
          className={
            classes[balanceToClass(currentValue - portfolio.totalInvested)]
          }
        >
          {Number(
            (currentValue - portfolio.totalInvested).toFixed(2)
          ).toLocaleString('pt-PT')}{' '}
          €
        </span>
      </p>
      <hr />
      <EvolutionChart data={portfolio} />
      <hr />
      <TWR data={portfolio} />
      <hr />
      <PLData assets={assets} transactions={transactions} />
      <hr />
      <h2>Composição actual</h2>
      <div className={classes.buttons}>
        <span className={classes.label}>Ver distribuição por:</span>
        <span className={classes.options}>
          <button
            className={classnames({
              [classes.selected]: view === 'types',
            })}
            onClick={() => {
              setView('types');
            }}
          >
            Categorias
          </button>
          <button
            className={classnames({
              [classes.selected]: view === 'assets',
            })}
            onClick={() => {
              setView('assets');
            }}
          >
            Activos
          </button>
        </span>
      </div>
      {view === 'types' && (
        <PortfolioByType
          data={assets}
          value={currentValue}
          typesNames={typesNames}
          typesOrder={typesOrder}
        />
      )}
      {view === 'assets' && (
        <PortfolioByAssets
          data={assets}
          value={currentValue}
          typesOrder={typesOrder}
        />
      )}
    </Article>
  );
};

export default Portfolio;
