import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import useRequest from 'utils/useRequest';
import classes from './Footer.module.scss';

const Footer = () => {
  const [email, setEmail] = useState('');
  const { data, loading, error, request } = useRequest({
    url: 'subscribers',
    method: 'POST',
    callback: true,
  });

  useEffect(() => {
    if (data) {
      toast.success('Subscrição feita. Obrigado!');
      setEmail('');
    }
    if (error) {
      toast.error('Ocorreu um erro ao subscrever.');
    }
  }, [data, error]);

  return (
    <footer className={classes.footer}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          request({ email });
        }}
      >
        <label className={classes.label} htmlFor="subscribe-input">
          Para receberes um aviso quando for publicado um novo artigo envia o
          teu e-mail
        </label>
        <input
          id="subscribe-input"
          className={classes.input}
          type="text"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />
        <button disabled={loading || !email} className={classes.submit}>
          Enviar
        </button>
      </form>
    </footer>
  );
};

export default Footer;
