import React, { useMemo } from 'react';
import { groupBy } from 'lodash';

import { useStateValue } from 'state/State';
import useRequest from 'utils/useRequest';
import Error from 'utils/Error';
import Loading from 'utils/Loading';
import Article from 'components/Article';

import classes from './NetWorth.module.scss';

const financial = (x) => Number.parseFloat(x.toFixed(2));

const NetWorth = () => {
  const [{ user }] = useStateValue();

  const {
    data: assets,
    loading: loadingAssets,
    error: errorAssets,
  } = useRequest({
    url: 'assets',
    method: 'GET',
  });

  const portfolioValue = useMemo(
    () =>
      assets?.reduce((acc, asset) => {
        if (!asset.Portfolio) {
          return acc;
        }
        return (acc += asset.Quote
          ? asset.Quantity * asset.Quote
          : asset.Quantity);
      }, 0),
    [assets]
  );

  const otherAssetsValue = useMemo(
    () =>
      assets?.reduce((acc, asset) => {
        if (asset.Portfolio) {
          return acc;
        }
        return (acc += asset.Quote
          ? asset.Quantity * asset.Quote
          : asset.Quantity);
      }, 0),
    [assets]
  );

  const {
    data: accounts,
    loading: loadingAccounts,
    error: errorAccounts,
  } = useRequest({
    url: 'bank-accounts',
  });

  const {
    data: transactions,
    loading: loadingTransactions,
    error: errorTransactions,
  } = useRequest({
    url: 'bank-transactions',
    method: 'GET',
    callback: !user,
  });

  const accByAccount = useMemo(() => {
    const transactionByAccount = groupBy(transactions, 'bank_account.id');
    return Object.keys(transactionByAccount).reduce((acc, item) => {
      acc[item] = transactionByAccount[item].reduce(
        (innerAcc, transaction) => financial(innerAcc + transaction.value),
        0
      );
      return acc;
    }, {});
  }, [transactions]);

  const accountBalances = useMemo(
    () =>
      accounts
        ?.map((account) => ({
          ...account,
          balance: financial(
            account.initial_balance + (accByAccount[account.id] || 0)
          ),
        }))
        .sort((a, b) => a.title.localeCompare(b.title)),
    [accounts, accByAccount]
  );

  if (!user) {
    return <Error error={403} />;
  }

  if (errorAssets || errorTransactions || errorAccounts) {
    return <Error />;
  }

  if (loadingAssets || loadingTransactions || loadingAccounts) {
    return <Loading />;
  }

  const accountBalancesTotal = accountBalances.reduce(
    (acc, item) => financial(acc + item.balance),
    0
  );

  return (
    <Article article={{ title: 'Património' }}>
      <div>
        <h2>Balanços das contas</h2>
        <ul className={classes.accounts}>
          {accountBalances.map((account) => (
            <li key={account.id}>
              <strong>{account.title}:</strong>{' '}
              {account.balance.toLocaleString('pt-PT')} €
            </li>
          ))}
        </ul>
        <strong>
          <u>Balanço total:</u>
        </strong>{' '}
        {accountBalancesTotal.toLocaleString('pt-PT')} €
        <hr />
        <h2>Balanço do investimento</h2>
        <ul className={classes.accounts}>
          {assets.map((asset) => {
            if (!asset.Portfolio) {
              return null;
            }
            return (
              <li key={asset.id}>
                <strong>{asset.Name}:</strong>{' '}
                {(asset.Quote
                  ? asset.Quantity * asset.Quote
                  : asset.Quantity
                ).toLocaleString('pt-PT')}{' '}
                €
              </li>
            );
          })}
        </ul>
        <strong>
          <u>Balanço total:</u>
        </strong>{' '}
        {Number(portfolioValue.toFixed(2)).toLocaleString('pt-PT')} €
        <hr />
        <h2>Outro património</h2>
        <ul className={classes.accounts}>
          {assets.map((asset) => {
            if (!asset.Portfolio) {
              return (
                <li key={asset.id}>
                  <strong>{asset.Name}:</strong>{' '}
                  {(asset.Quote
                    ? asset.Quantity * asset.Quote
                    : asset.Quantity
                  ).toLocaleString('pt-PT')}{' '}
                  €
                </li>
              );
            }
            return null;
          })}
        </ul>
        <strong>
          <u>Balanço total:</u>
        </strong>{' '}
        {Number(otherAssetsValue.toFixed(2)).toLocaleString('pt-PT')} €
        <hr />
        <h2>Saldo total do património</h2>
        {Number(
          (accountBalancesTotal + portfolioValue + otherAssetsValue).toFixed(2)
        ).toLocaleString('pt-PT')}{' '}
        €
      </div>
    </Article>
  );
};

export default NetWorth;
