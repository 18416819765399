import React from 'react';

import classes from './Error.module.scss';

const Error = ({ error = 'default' }) => (
  <article className={classes.error}>
    <div className={classes.icon}>
      {error === 404 && <i className="fas fa-heart-broken"></i>}
      {error === 403 && <i className="fas fa-ban"></i>}
      {error === 'default' && <i className="fas fa-bomb"></i>}
    </div>
    <p>
      {error === 404 && 'A página que estás a tentar aceder não existe'}
      {error === 403 && 'Não tens acesso à página que estás a tentar aceder'}
      {error === 'default' && 'Ocorreu um erro. Por favor volta mais tarde.'}
    </p>
  </article>
);

export default Error;
