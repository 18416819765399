import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import useRequest from 'utils/useRequest';

import classes from './Unsubscribe.module.scss';

const Unsubscribe = () => {
  const [email, setEmail] = useState('');
  const { data, loading, error, request } = useRequest({
    url: `subscribers/${email}`,
    method: 'DELETE',
    callback: true,
  });

  useEffect(() => {
    if (data?.length > 0) {
      toast.success('Subscrição cancelada.');
      setEmail('');
    }
    if (data?.length === 0 || error) {
      toast.error('Ocorreu um erro ao cancelar a subscrição.');
    }
  }, [data, error]);

  return (
    <form
      className={classes.form}
      onSubmit={(e) => {
        e.preventDefault();
        request({ email });
      }}
    >
      <label className={classes.label} htmlFor="unsubscribe-input">
        Insere o e-mail onde queres deixar de receber os avisos de novos artigos
      </label>
      <input
        id="unsubscribe-input"
        className={classes.input}
        type="text"
        value={email}
        onChange={(e) => {
          setEmail(e.target.value);
        }}
      />
      <button disabled={loading || !email} className={classes.submit}>
        Cancelar
      </button>
    </form>
  );
};

export default Unsubscribe;
