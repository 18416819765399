import React from 'react';
import moment from 'moment';
import classnames from 'classnames';

import TWRChart from './TWRChart';

import classes from '../Portfolio.module.scss';

const yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
const currentYear = yesterday.getFullYear();

const TWR = ({ data }) => {
  const firstYear = moment(data.Inception).format('YYYY');
  let year = firstYear;
  let years = [];
  while (year <= currentYear) {
    years.push(year);
    year++;
  }

  return (
    <>
      <TWRChart data={data} />
      <div className={classes.tableWrapper}>
        <table className={classes.returnsTable}>
          <thead>
            <tr>
              <th>Ano</th>
              <th>Jan</th>
              <th>Fev</th>
              <th>Mar</th>
              <th>Abr</th>
              <th>Mai</th>
              <th>Jun</th>
              <th>Jul</th>
              <th>Ago</th>
              <th>Set</th>
              <th>Out</th>
              <th>Nov</th>
              <th>Dez</th>
            </tr>
          </thead>
          <tbody>
            {years.map((year) => (
              <tr key={year}>
                <th>{year}</th>
                {[...Array(12).keys()].map((month) => {
                  const monthData =
                    data.portfolioMonthlyTWR[
                      `${year}-${(month + 1).toString().padStart(2, '0')}`
                    ];
                  const value = monthData
                    ? Math.round(monthData * 10) / 10
                    : undefined;
                  return (
                    <td
                      key={month}
                      className={classnames({
                        [classes.negative]: value < 0,
                        [classes.positive]: value > 0,
                        [classes.neutral]: value === 0,
                      })}
                    >
                      {typeof value !== 'undefined' &&
                        `${Number(value.toFixed(1)).toLocaleString(
                          'pt-PT'
                        )}%`}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default TWR;
