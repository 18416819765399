import React from 'react';

import classes from '../Simulator.module.scss';

const AGES = Array.from({ length: 100 }, (_, i) => i + 1);

const PersonalData = ({
  currentAge,
  setCurrentAge,
  barista,
  setBarista,
  baristaAge,
  setBaristaAge,
  retirementAge,
  setRetirementAge,
  legalRetirementAge,
  setLegalRetirementAge,
}) => (
  <fieldset className={classes.fieldset}>
    <legend>Dados pessoais</legend>
    <label className={classes.label}>
      Idade actual{' '}
      <select
        value={currentAge}
        onChange={(e) => setCurrentAge(parseInt(e.target.value))}
      >
        {AGES.map((age) => {
          let validAge = false;
          if (barista) {
            if (age <= baristaAge) {
              validAge = true;
            }
          } else if (age <= retirementAge) {
            validAge = true;
          }
          return validAge ? (
            <option key={age} value={age}>
              {age}
            </option>
          ) : null;
        })}
      </select>
    </label>
    <fieldset className={classes.fieldset}>
      <label className={classes.label}>
        Parar investimento antes da reforma{' '}
        <input
          type="checkbox"
          checked={Boolean(Number(barista))}
          value={barista}
          onChange={() => {
            if (
              !barista &&
              !(baristaAge >= currentAge && baristaAge <= retirementAge)
            ) {
              const newBaristaAge = Math.round(
                currentAge + (retirementAge - currentAge) * (2 / 3)
              );
              setBaristaAge(newBaristaAge);
            }
            setBarista((prev) => Number(!prev));
          }}
        />
      </label>
      {barista > 0 && (
        <label className={classes.label}>
          Idade de paragem de investimento{' '}
          <select
            value={baristaAge}
            onChange={(e) => setBaristaAge(parseInt(e.target.value))}
          >
            {AGES.map((age) => {
              if (age <= retirementAge && age >= currentAge) {
                return (
                  <option key={age} value={age}>
                    {age}
                  </option>
                );
              }
              return null;
            })}
          </select>
        </label>
      )}
    </fieldset>
    <label className={classes.label}>
      Idade da reforma pretendida{' '}
      <select
        value={retirementAge}
        onChange={(e) => setRetirementAge(parseInt(e.target.value))}
      >
        {AGES.map((age) => {
          let validAge = false;
          if (barista) {
            if (age >= baristaAge && age <= legalRetirementAge) {
              validAge = true;
            }
          } else if (age >= currentAge && age <= legalRetirementAge) {
            validAge = true;
          }
          return validAge ? (
            <option key={age} value={age}>
              {age}
            </option>
          ) : null;
        })}
      </select>
    </label>
    <label className={classes.label}>
      Idade legal da reforma{' '}
      <select
        value={legalRetirementAge}
        onChange={(e) => setLegalRetirementAge(parseInt(e.target.value))}
      >
        {AGES.map((age) => {
          if (age >= retirementAge) {
            return (
              <option key={age} value={age}>
                {age}
              </option>
            );
          }
          return null;
        })}
      </select>
    </label>
  </fieldset>
);

export default PersonalData;
