import React, { useMemo } from 'react';
import { Chart, ArcElement, Tooltip } from 'chart.js';
import { Pie } from 'react-chartjs-2';

import classes from '../Portfolio.module.scss';

const formatNumber = (number, fractionDigits = 10) => {
  const string = Number(number)
    .toLocaleString('pt-PT', {
      minimumFractionDigits: fractionDigits,
      maximumFractionDigits: fractionDigits,
    })
    .replace(/0+$/, '');

  if (isNaN(string.slice(-1))) {
    return string.slice(0, -1);
  }
  return string;
};

Chart.register(ArcElement, Tooltip);

const PortfolioByAssets = ({ data, value, typesOrder }) => {
  const assets = useMemo(
    () =>
      data
        .map((asset) => ({
          ...asset,
          total: asset.Quote ? asset.Quantity * asset.Quote : asset.Quantity,
        }))
        .sort((a, b) => typesOrder[a.Type] - typesOrder[b.Type]),
    [data, typesOrder]
  );

  return (
    <>
      <table className={classes.assetsTable}>
        <thead>
          <tr>
            <th className={classes.name}>Activo</th>
            <th className={classes.qty}>Quantidade</th>
            <th className={classes.quote}>Cotação</th>
            <th className={classes.total}>Valor total</th>
          </tr>
        </thead>
        <tbody>
          {assets.map((asset) => (
            <tr className={classes[asset.Type]} key={asset.id}>
              <th className={classes.name}>
                <span className={classes[asset.Type]}>{asset.Name}</span>
              </th>
              <td className={classes.qty}>
                <span className={classes[asset.Type]}>
                  {asset.Quote ? formatNumber(asset.Quantity) : '-'}
                </span>
              </td>
              <td className={classes.quote}>
                <span className={classes[asset.Type]}>
                  {asset.Quote ? `${formatNumber(asset.Quote)} €` : '-'}
                </span>
              </td>
              <td className={classes.total}>
                <strong className={classes[asset.Type]}>
                  {formatNumber(asset.total, 2)} €
                </strong>
              </td>
            </tr>
          ))}
          <tr className={classes.total}>
            <th className={classes.name}>
              <span className={classes.total}>Total</span>
            </th>
            <td className={classes.qty}>
              <span className={classes.total}>&nbsp;</span>
            </td>
            <td className={classes.quote}>
              <span className={classes.total}>&nbsp;</span>
            </td>
            <td className={classes.total}>
              <strong className={classes.total}>
                {formatNumber(value, 2)} €
              </strong>
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <div className={classes.pieChartContainer}>
        <Pie
          data={{
            labels: assets.map(
              (asset) =>
                `${asset.Name} (${formatNumber(
                  (100 * asset.total) / value,
                  2
                )}%)`
            ),
            datasets: [
              {
                data: assets.map((asset) =>
                  (100 * asset.total) / value < 1
                    ? 1
                    : (100 * asset.total) / value
                ),
                backgroundColor: assets.map((asset) => {
                  if (asset.Type === 'stock') {
                    return '#e1523e';
                  }
                  if (asset.Type === 'bond') {
                    return '#2271b1';
                  }
                  if (asset.Type === 'gold') {
                    return '#ffa400';
                  }
                  if (asset.Type === 'ppr') {
                    return '#80F3D9';
                  }
                  if (asset.Type === 'crypto') {
                    return '#712F79';
                  }
                  if (asset.Type === 'cash') {
                    return '#50a684';
                  }
                  return '#FFF';
                }),
                hoverOffset: 20,
              },
            ],
          }}
          options={{
            layout: {
              padding: 20,
            },
            plugins: {
              tooltip: {
                callbacks: {
                  label: function (context) {
                    return context.label;
                  },
                },
              },
              legend: {
                display: false,
              },
            },
          }}
        />
      </div>
    </>
  );
};

export default PortfolioByAssets;
