import React from 'react';

import useRequest from 'utils/useRequest';
import Loading from 'utils/Loading';
import Error from 'utils/Error';
import Article from 'components/Article';

const About = () => {
  const { data, loading, error } = useRequest({
    url: 'about',
    method: 'GET',
  });

  if (loading) {
    return <Loading />;
  }

  if (error || !data) {
    return <Error />;
  }

  return <Article article={data} />;
};

export default About;
