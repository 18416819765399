import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import Cookies from 'js-cookie';

import { useStateValue } from 'state/State';

import LoginModal from './LoginModal';

import classes from './Header.module.scss';

const Header = () => {
  const [{ user }] = useStateValue();
  const [menuOpen, setMenuOpen] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  return (
    <>
      <header className={classes.header}>
        <nav
          onClick={() => {
            setMenuOpen(false);
          }}
        >
          <Link className={classes.link} to="/">
            IFRA
          </Link>
          <div
            className={classnames(classes.extraLinks, {
              [classes.open]: menuOpen,
            })}
          >
            <Link className={classes.link} to="/arquivo">
              Arquivo
            </Link>
            <Link className={classes.link} to="/sobre">
              Sobre
            </Link>
            <Link className={classes.link} to="/carteira">
              Carteira
            </Link>
            {user && (
              <Link className={classes.link} to="/movimentos">
                Movimentos
              </Link>
            )}
            {user && (
              <Link className={classes.link} to="/patrimonio">
                Património
              </Link>
            )}
            <Link className={classes.link} to="/indicadores">
              Indicadores
            </Link>
            <Link className={classes.link} to="/simulador">
              Simulador
            </Link>
            <Link className={classes.link} to="/recursos">
              Recursos
            </Link>
            {!user ? (
              <button
                className={classes.login}
                onClick={() => {
                  setLoginModal(true);
                }}
              >
                🔥
              </button>
            ) : (
              <button
                className={classes.login}
                onClick={() => {
                  Cookies.remove('AUTH-TOKEN');
                  window.location.reload();
                }}
              >
                👋
              </button>
            )}
          </div>
        </nav>
        <div>
          {!user ? (
            <button
              className={classes.login}
              onClick={() => {
                setLoginModal(true);
              }}
            >
              🔥
            </button>
          ) : (
            <button
              className={classes.login}
              onClick={() => {
                Cookies.remove('AUTH-TOKEN');
                window.location = '/';
              }}
            >
              👋
            </button>
          )}
          <button
            className={classnames(classes.buttonIcon, {
              [classes.active]: menuOpen,
            })}
            onClick={() => {
              setMenuOpen((prev) => !prev);
            }}
          >
            <div className={classes.top}></div>
            <div className={classes.mid}></div>
            <div className={classes.bot}></div>
          </button>
        </div>
      </header>
      {loginModal && <LoginModal onClose={() => setLoginModal(false)} />}
    </>
  );
};

export default Header;
